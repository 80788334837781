import React, { useEffect, useState } from "react";
import "./RiskCard.scss";
import { Fade } from "react-reveal";
import Button from "../../../../Components/Button/Button";
import { RiskIcon } from "../../../../Utils/risk";
import { useNavigate } from "react-router-dom";

const RiskCard = ({ color, risk, lightColor, title, delay, totalAssets, url }) => {
    const [stopAnim, setStopAnim] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => setStopAnim(true), 2800);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Fade bottom delay={delay}>
            <div
                className={`risk-card h-100 rounded-3 w-100 p-3 d-flex align-items-center justify-content-start gap-5 gray-border position-relative flex-column ${
                    stopAnim ? "risk-card-no-anim" : ""
                }`}>
                <div className="shiny-bar" style={{ "--i": `${color}` }}></div>

                <RiskIcon
                    poolRisk={risk}
                    title={title?.toUpperCase()}
                    lightColor={lightColor}
                    color={color}
                />

                <div className="d-flex h-100 align-items-center justify-content-around flex-column w-100">
                    <div className="d-flex align-items-center justify-content-start flex-column gap-2">
                        {/* <span className='font-size-48 font-pnb color-main-black line-height-1'>${totalAssets} mil</span> */}
                        <span className="font-size-40 font-pnb color-main-black line-height-1">
                            {totalAssets}
                        </span>
                        <span className="font-size-16 font-pnb color-main-gray line-height-1">
                            Total assets value
                        </span>
                    </div>

                    <Button
                        title={"Learn more"}
                        arrow={"right"}
                        color={lightColor}
                        outlineColor={"transparent"}
                        bgColor={"transparent"}
                        hoverColor={color}
                        callback={() => navigate(url)}
                        // align={"c"}
                        // specialPadding={"0px"}
                    />
                </div>
                {/* <div className='shield-risk-container my-auto pointer' style={{ color: `${lightColor}` }}>
                    <PlusIco />
                </div> */}
            </div>
        </Fade>
    );
};

export default RiskCard;
