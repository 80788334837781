import React from "react";
import "./Card.scss";
import { Col, Row } from "react-bootstrap";
import { ReactComponent as ShieldIco } from "../../../../Assets/svg/shield-check.svg";
import Title from "../../../../Components/Title/Title";
import Button from "../../../../Components/Button/Button";

const Card = ({ uppertitle, title, text, image, orientation }) => {
    return (
        <div
            className={`get-covered-card w-100 d-flex gray-border p-3 p-lg-5 ${
                orientation === "right" ? "ms-auto" : "me-auto"
            }`}>
            <Row
                className={`flex-column-reverse ${
                    orientation !== "right" ? "flex-sm-row-reverse" : "flex-sm-row"
                }`}>
                <Col className="w-100 h-auto p-4 pb-0 pb-md-4 d-flex align-items-start justify-content-between flex-column">
                    <span className="mb-2 font-size-16 font-pnm uppertitle-card d-flex align-items-center justify-content-center gap-2 color-light-green rounded-2">
                        <ShieldIco /> {uppertitle?.toUpperCase()}
                    </span>
                    <Title title={title} punctuation={"."} />
                    <span className="mb-3 color-main-gray font-size-18 font-pnr pe-3 line-height-14">
                        {text}
                    </span>

                    <Button
                        title={"Learn more"}
                        arrow={"right"}
                        color={"#12784A"}
                        outlineColor={"transparent"}
                        bgColor={"transparent"}
                        hoverColor={"#000000"}
                        align={"start"}
                        specialPadding={"0px"}
                    />
                </Col>
                <Col className="h-100  d-flex align-items-center justify-content-center">
                    <img src={image} alt="card" className="img-fluid mx-auto image-card" />
                </Col>
            </Row>
        </div>
    );
};

export default Card;
