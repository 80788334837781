import React from "react";
import UpperTitle from "../../../Components/UpperTitle/UpperTitle";
import "./CryptoAssets.scss";

import placer_1 from "./Assets/placer_1.png";
import placer_2 from "./Assets/placer_2.png";
import placer_3 from "./Assets/placer_3.png";
import placer_4 from "./Assets/placer_4.png";
import placer_5 from "./Assets/placer_5.png";
import placer_6 from "./Assets/placer_6.png";
import placer_7 from "./Assets/placer_7.png";

const cardsData = [
    {
        img: placer_1,
        title: (
            <>
                Sharing Risk <br /> <span className="color-main-green">Mutual Fund</span> Concept.
            </>
        ),
        text: "Martoms' concept of mutual funds is an innovative solution for managing risk. By pooling funds from multiple guarantors, it creates a decentralized system that shares the risk among many parties.",
        className: "col-md-6 col-12",
        height: 500
    },
    {
        img: placer_2,
        title: (
            <>
                All Funds are <br /> <span className="color-main-green">Guaranteed</span> by
                participants.
            </>
        ),
        text: "The bonds we offer are guaranteed by the DAO pool, a collective effort where participants contribute their resources. This autonomous platform operates through smart contracts and community-established rules.",
        className: "col-md-6 col-12 mt-md-0 mt-4",
        height: 500
    },
    {
        img: placer_3,
        title: (
            <>
                <span className="color-main-green">Everything is transparent</span> <br /> & public
                for Everybody.
            </>
        ),
        text: "By leveraging the power of decentralized governance, we ensure transparency, fairness, and accountability in our operations.",
        className: "col-lg-4 col-md-6 col-12 mt-4",
        height: 500,
        blendMode: "hard-light"
    },
    {
        img: placer_4,
        title: (
            <>
                Experts <br /> working to <br /> provide you <br />{" "}
                <span className="color-main-green">accurate data.</span>
            </>
        ),
        text: "Our team of experts works together to ensure you receive reliable and up-to-date information. With a commitment to excellence, we strive to provide you with the most precise and trustworthy data for informed decision-making.",
        className: "col-lg-4 col-md-6 col-12 mt-4",
        height: 500,
        blendMode: "hard-light"
    },
    {
        img: placer_5,
        title: (
            <>
                <span className="color-main-green">
                    Audited <br /> Projects
                </span>{" "}
                <br /> with Public <br /> Documentation.
            </>
        ),
        text: "We prioritize accountability and trust by thoroughly auditing projects and ensuring they adhere to strict standards. With publicly available documentation, you can review and assess the project's details, including goals, roadmap, team, and financials.",
        className: "col-lg-4 col-md-6 col-12 mt-4",
        height: 500,
        blendMode: "hard-light"
    },
    {
        img: placer_6,
        title: (
            <>
                Customer Support & <br /> Brokers to help you <br />
                to <span className="color-main-green">use all functions.</span>
            </>
        ),
        text: "Comprehensive customer support and expert brokers dedicated to assisting you in utilizing all platform functions. Our team is committed to providing you with the guidance and assistance you need to make the most of our services.",
        className: "col-lg-5 col-md-6 col-12 mt-4",
        height: 500,
        blendMode: "hard-light"
    },
    {
        img: placer_7,
        title: (
            <>
                <span className="color-main-green">Easy to use</span> without <br /> knowledege in
                Crypto & Blockchain.
            </>
        ),
        text: "Our platform is designed to be user-friendly and accessible, even for individuals without prior knowledge in crypto and blockchain. You don't need to be an expert to navigate our platform and utilize its features effectively.",
        className: "col-lg-7 col-12 mt-4",
        height: 500,
        blendMode: "hard-light"
    }
];

const CryptoAssets = () => {
    return (
        <section id="homepage-crypto-assets" className="sections-spacing">
            <div className="container-main d-flex align-items-start justify-content-start w-100 flex-column gap-3">
                <div className="d-flex align-items-center justify-content-center flex-column gap-2 w-100">
                    <UpperTitle
                        title={"WHY TO CHOOSE MARTOMS BROTHERS & CO"}
                        className="bg-main-black color-light-green px-2 rounded-1 mb-2"
                    />
                    <span
                        className={`px-3 title-span font-size-64 font-pnb d-flex flex-column align-items-center justify-content-center color-main-black line-height-1 mb-3 mb-lg-5`}
                        style={{ color: "#323232" }}>
                        <div>
                            Your smartest choice <br className="d-none d-sm-block" />{" "}
                            <span className="color-main-green"> to secure your crypto assets.</span>
                        </div>
                    </span>
                </div>

                <div className="row w-100 m-0">
                    {cardsData.map((el) => (
                        <div className={el.className}>
                            <div
                                className="placeholder-card radius-8 p-5 d-flex justify-content-end flex-column"
                                style={{
                                    height: el.height,
                                    backgroundBlendMode: el.blendMode,
                                    background: `#F5F5F5 url(${el.img}) no-repeat center center`
                                }}>
                                <h2 className="font-size-40 font-pnb">{el.title}</h2>
                                <span className="font-size-18 color-main-gray">{el.text}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CryptoAssets;
