import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap';
import Title from '../../../Components/Title/Title';
import UpperTitle from '../../../Components/UpperTitle/UpperTitle';
import FAQImg from "../Assets/FAQ.png";
import { HiOutlinePlus as PlusIco } from "react-icons/hi"

import "./FAQ.scss";

const FAQData = [
    {
        question: "What is the exchange coverage?",
        answer: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. A wonderful serenity has taken possession of my entire soul."
    },
    {
        question: "Why do you need an exchange coverage?",
        answer: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. A wonderful serenity has taken possession of my entire soul."
    },
    {
        question: "How an exchange coverage can protect your assets?",
        answer: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. A wonderful serenity has taken possession of my entire soul."
    },
    {
        question: "What are the main assets covered by the exchange coverage?",
        answer: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine. A wonderful serenity has taken possession of my entire soul."
    },
]

const FAQ = () => {
    return (
        <section id="exchange-faq" className='sections-spacing'>
            <div className='container-main d-flex align-items-start justify-content-start w-100 flex-column'>
                <UpperTitle title={"FAQ"} />
                <Title title={"If you missed anything"} punctuation={""} color={"#323232"} spacing={"big"} />

                <Row>
                    <Col md={12} lg={6} className="order-2 order-lg-1">
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                            <Accordion defaultActiveKey="0">
                                {
                                    FAQData.map((faq, i) => {
                                        return (
                                            <React.Fragment key={"faq" + i}>
                                                <Accordion.Item eventKey={`${i}`} className="gray-border">
                                                    <Accordion.Header>
                                                        <div className='d-flex align-items-start justify-content-between w-100 gap-3'>
                                                            <span className='question font-size-20 font-pnb color-main-black'>{faq.question}</span>
                                                            <div className='d-flex align-items-center justify-content-center plus-container'>
                                                                <PlusIco />
                                                            </div>
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <span className='answer font-size-18 font-pnr color-main-gray'>{faq.answer}</span>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Accordion>
                        </div>
                    </Col>
                    <Col md={12} lg={6} className="order-1 order-lg-2">
                        <div className='d-flex align-items-center justify-content-center w-100 p-4'>
                            <img src={FAQImg} alt="FAQ" className='img-fluid faq-img' />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>

    )
}

export default FAQ