import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../Components/Button/Button';
import Title from '../../../../Components/Title/Title'
import UpperTitle from '../../../../Components/UpperTitle/UpperTitle'
import { howItWorksCustomRefSection1 } from '../../../../Utils/Refs';
import DangersImg from "../../Assets/dangers.png";
import { ReactComponent as Icon1 } from "../../Assets/icon1.svg"
import { ReactComponent as Icon2 } from "../../Assets/icon2.svg"

import "./InfoSection.scss";

const InfoSection = () => {
    return (
        <section id="how-it-works-custom-info" className='sections-spacing' ref={howItWorksCustomRefSection1.ref}>
            <div className='container-main d-flex align-items-center justify-content-center flex-column'>
                <UpperTitle title={"CUSTOM POOLS"} />
                <Title title={"How it works"} punctuation={""} color={"#323232"} spacing={"big"} />

                <Row >
                    <Col xs={12} sm={6} md={6} lg={3} className="order-2 order-lg-1">
                        <div className='w-100 h-100 d-flex flex-column align-items-lg-end align-items-start justify-content-center gap-3'>

                            <Icon1 />

                            <span className='font-size-24 font-pnb text-lg-end text-start color-main-black line-height-12'>How to make a deposit?</span>
                            <span className='font-size-16 font-pnr text-lg-end text-start color-main-gray'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot. </span>

                            <Button
                                title={"Learn more"}
                                arrow={"right"}
                                color={"#1D98B5"}
                                outlineColor={"transparent"}
                                bgColor={"transparent"}
                                hoverColor={"#000000"}
                                align={"start"}
                                specialPadding={"0px"}
                                customClass="align-items-end justify-content-end"
                            />
                        </div>
                    </Col>

                    <Col md={12} lg={6} className="order-1 order-lg-2 mb-4 mb-lg-0">
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                            <img src={DangersImg} alt="dangers" className='img-fluid big-img' />
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={3} className="order-3 order-lg-3">
                        <div className='w-100 h-100 flex-column d-flex align-items-start justify-content-center gap-3 margin-top'>
                            <Icon2 />

                            <span className='font-size-24 font-pnb color-main-black line-height-12'>How to vote for project?</span>
                            <span className='font-size-16 font-pnr color-main-gray'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot. </span>

                            <Button
                                title={"Learn more"}
                                arrow={"right"}
                                color={"#1D98B5"}
                                outlineColor={"transparent"}
                                bgColor={"transparent"}
                                hoverColor={"#000000"}
                                align={"start"}
                                specialPadding={"0px"}
                                customClass="align-items-start justify-content-start"
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>

    )
}

export default InfoSection