import React from 'react'
import Title from '../../../Components/Title/Title'
import UpperTitle from '../../../Components/UpperTitle/UpperTitle'
import { ReactComponent as UmbrellaIco } from "../Assets/umbrella2.svg"
import { ReactComponent as BankIco } from "../Assets/bank2.svg"
import { ReactComponent as ShieldIco } from "../Assets/shield2.svg"
import IndicatorImg from "../Assets/bottom-line1.png"
import IndicatorImg2 from "../Assets/bottom-line12.png"
import "./RolesCustom.scss";
import { Col, Row } from 'react-bootstrap'

const RolesCustom = () => {
    return (
        <section id="pools-roles-custom" className='sections-spacing overflow-hidden'>
            <div className='container-main d-flex align-items-start justify-content-start flex-column'>
                <UpperTitle title={"CUSTOM POOLS"} color={true} />
                <Title title={"Roles"} punctuation={"."} color={"#FFFFFF"} />

                <Row xs={1} sm={1} md={1} lg={3} className="bottom-line">
                    <Col>
                        <div className='role-col-container d-flex align-items-tart justify-content-between flex-column'>
                            <div className='align-items-start justify-content-start flex-row gap-2 d-flex d-lg-none'>
                                <img src={IndicatorImg2} alt="point" className='img-fluid indicator-img' />
                                <span className='font-size-24 font-pnb color-white line-height-13 ps-1 align-self-center'>01</span>
                            </div>
                            <div className='ps-3 ps-lg-0 pt-3 pt-lg-0 d-flex align-items-start justify-content-start flex-column pe-5'>
                                <UmbrellaIco />
                                <span className='font-size-24 font-pnb color-white line-height-13 mt-3 mb-2'>Insures existing fund</span>
                                <span className='font-size-16 font-pnr color-second-gray line-height-13'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot.</span>
                            </div>
                            <div className='align-items-start justify-content-start flex-column gap-2 d-none d-lg-flex'>
                                <span className='font-size-24 font-pnb color-white line-height-13 ps-1'>01</span>
                                <img src={IndicatorImg} alt="point" className='img-fluid indicator-img' />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='d-flex align-items-tart justify-content-between flex-column gap-3'>
                            <div className='role-col-container d-flex align-items-tart justify-content-between flex-column'>
                                <div className='align-items-start justify-content-start flex-row gap-2 d-flex d-lg-none pt-5'>
                                    <img src={IndicatorImg2} alt="point" className='img-fluid indicator-img' />
                                    <span className='font-size-24 font-pnb color-white line-height-13 ps-1 align-self-center'>02</span>
                                </div>
                                <div className='ps-3 ps-lg-0 pt-3 pt-lg-0 d-flex align-items-start justify-content-start flex-column pe-5'>
                                    <BankIco />
                                    <span className='font-size-24 font-pnb color-white line-height-13 mt-3 mb-2'>Deposit & Withdraw funds.</span>
                                    <span className='font-size-16 font-pnr color-second-gray line-height-13'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot.</span>
                                </div>
                                <div className='align-items-start justify-content-start flex-column gap-2 d-none d-lg-flex'>
                                    <span className='font-size-24 font-pnb color-white line-height-13 ps-1'>02</span>
                                    <img src={IndicatorImg} alt="point" className='img-fluid indicator-img' />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className='d-flex align-items-tart justify-content-between flex-column gap-3'>
                            <div className='role-col-container d-flex align-items-tart justify-content-between flex-column'>
                                <div className='align-items-start justify-content-start flex-row gap-2 d-flex d-lg-none pt-5'>
                                    <img src={IndicatorImg2} alt="point" className='img-fluid indicator-img' />
                                    <span className='font-size-24 font-pnb color-white line-height-13 ps-1 align-self-center'>03</span>
                                </div>
                                <div className='ps-3 ps-lg-0 pt-3 pt-lg-0 d-flex align-items-start justify-content-start flex-column pe-5'>
                                    <ShieldIco />
                                    <span className='font-size-24 font-pnb color-white line-height-13 mt-3 mb-2'>Automatically executes products.</span>
                                    <span className='font-size-16 font-pnr color-second-gray line-height-13'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot.</span>
                                </div>
                                <div className='align-items-start justify-content-start flex-column gap-2 d-none d-lg-flex'>
                                    <span className='font-size-24 font-pnb color-white line-height-13 ps-1'>03</span>
                                    <img src={IndicatorImg} alt="point" className='img-fluid indicator-img' />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default RolesCustom