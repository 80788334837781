import React from "react";
import Title from "../../../Components/Title/Title";
import UpperTitle from "../../../Components/UpperTitle/UpperTitle";
import "./HowItWorks.scss";
import StepComponent from "./StepComponent/StepComponent";

const HowItWorks = () => {
    return (
        <section id="homepage-how-it-works" className="sections-spacing">
            <div className="container-main d-flex align-items-center justify-content-center flex-column">
                <UpperTitle
                    className="bg-dark color-light-green px-3 py-1 rounded-1 mb-2"
                    title={"Online EASY steps"}
                    color={true}
                />
                <Title title={"Easy for everybody."} punctuation={"."} color={"#FFFFFF"} />

                <span className="paragraph-container font-size-18 font-pnr color-second-gray text-center line-height-14 mb-4 px-3">
                    Ensuring the essential protection for crypto assets and liberating oneself from
                    concerns regarding loss or investments in poorly managed projects is a
                    straightforward endeavor that anyone can undertake on Martoms Brothers & CO. in
                    three easy steps.
                </span>

                <div className="d-flex align-items-start justify-content-between w-100 mt-3 mt-lg-5 flex-column flex-sm-row">
                    <StepComponent
                        number={"1"}
                        title={"Generate"}
                        content={
                            "Create your account and select the bond type that aligns with your specific needs."
                        }
                    />
                    <div className="separator-step d-none d-sm-block"></div>
                    <StepComponent
                        number={"2"}
                        title={"Claim"}
                        content={
                            "When the need arises, initiate a claim on your selected bond and you can remain worry-free."
                        }
                    />
                    <div className="separator-step d-none d-sm-block"></div>
                    <StepComponent
                        number={"3"}
                        title={"Recover"}
                        content={
                            "Recovering your funds utilizing the bond is a clear, fast, and optimized process."
                        }
                    />
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
