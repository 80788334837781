import React from 'react'
import "./HeroCards.scss"
import SliderGrab from '../../../Components/SliderGrab/SliderGrab'
// import Fade from 'react-reveal'
import { ReactComponent as LowRiskIco } from "../../../Assets/svg/low-risk.svg"
import { AiOutlineInfoCircle as InfoIco } from "react-icons/ai"
import { ReactComponent as FundsAvailableGreen } from "../../../Components/PoolList/poolListAssets/green/funds-available.svg"
import { ReactComponent as ActiveBondsGreen } from "../../../Components/PoolList/poolListAssets/green/active-bonds.svg"
import { ReactComponent as BackupFundsGreen } from "../../../Components/PoolList/poolListAssets/green/backup-funds.svg"

const HeroCards = () => {
    return (
        <section id="exchange-hero-cards" className='sections-spacing pt-0'>
            {/* <div className="d-flex align-items-center justify-content-center h-100"></div> */}
            <SliderGrab>
                <div className='container-main d-flex w-100 align-items-center justify-content-between gap-4'>
                    <div className='p-3 main-card d-flex align-items-center justify-content-center gap-2 flex-column'>
                        <div className='px-2 py-1 risk-container font-size-16 font-pnm color-light-green d-flex align-items-center justify-content-start gap-2'>
                            <LowRiskIco />
                            LOW RISK POOL
                        </div>
                        <span className='font-size-48 font-pnb text-center color-white'>
                            $13.52 mil
                        </span>
                        <div className='d-flex align-items-center jsutify-content-center gap-2 color-white'>
                            <span className='font-size-16 font-pnm text-center'>Total assets value</span>
                            <InfoIco />
                        </div>

                    </div>

                    <div className='p-3 px-5 gray-border big-card d-flex align-items-center justify-content-between gap-3'>

                        <div className='big-card-container  d-flex align-items-center justify-content-start gap-3'>
                            <FundsAvailableGreen />
                            <div className='d-flex flex-column align-items-start justify-content-between gap-3'>
                                <span className='color-main-gray font-size-16 font-pnm line-height-1'>Funds available</span>
                                <span className='color-main-black font-size-40 font-pnb line-height-1'>$45.52 mil</span>
                            </div>
                        </div>
                        <div className='separator'></div>
                        <div className='big-card-container d-flex align-items-center justify-content-start gap-3'>
                            <ActiveBondsGreen />
                            <div className='d-flex flex-column align-items-start justify-content-between gap-3'>
                                <span className='color-main-gray font-size-16 font-pnm line-height-1'>Active bonds</span>
                                <span className='color-main-black font-size-40 font-pnb line-height-1'>52.145</span>
                            </div>
                        </div>
                        <div className='separator'></div>
                        <div className='big-card-container d-flex align-items-center justify-content-start gap-3'>
                            <BackupFundsGreen />
                            <div className='d-flex flex-column align-items-start justify-content-between gap-3'>
                                <span className='color-main-gray font-size-16 font-pnm line-height-1'>Backup funds</span>
                                <span className='color-main-black font-size-40 font-pnb line-height-1'>$213.52 mil</span>
                            </div>
                        </div>

                    </div>
                    <div className='d-block d-lg-none' style={{ minWidth: "10px", color: "transparent" }}>.</div>
                </div>
            </SliderGrab>

        </section>
    )
}

export default HeroCards