import React, { useEffect, useState } from "react";
import "./SliderRight.scss";
import { detectEnd, detectStart } from "./SliderUtils";

let isDown = false;
let startX;
let scrollLeft;

const mouseLeaveFunction = () => {
    isDown = false;
};
const mouseDownFunction = (e, sliderRef, setRerender, rerender) => {
    isDown = true;
    startX = e.pageX - sliderRef.current.offsetLeft;
    scrollLeft = sliderRef.current.scrollLeft;
    setRerender(!rerender);
};
const mouseUpFunction = () => {
    isDown = false;
};
const touchFunction = (setRerender, rerender) => {
    isDown = false;
    setRerender(!rerender);
};
const mouseMoveFunction = (e, sliderRef) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 1; //scroll-fast
    sliderRef.current.scrollLeft = scrollLeft - walk;
};

const SliderRight = ({ children, sliderRef, atStart, setAtStart, atEnd, setAtEnd }) => {
    const [rerender, setRerender] = useState(false);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            detectStart(atStart, setAtStart, setAtEnd, sliderRef);
            detectEnd(atEnd, setAtEnd, setAtStart, sliderRef);
        }, 500);

        return () => {
            clearTimeout(timeOut);
        };
    }, [rerender]);

    return (
        <div className="slider-right text-unselectable container-main second-container d-flex position-relative w-100">
            <div
                ref={sliderRef}
                className="d-flex text-unselectable align-items-start justify-content-start gap-4 overflow-slider my-3"
                onMouseDown={(e) => mouseDownFunction(e, sliderRef, setRerender, rerender)}
                onMouseLeave={() => mouseLeaveFunction()}
                onMouseUp={() => mouseUpFunction()}
                onMouseMove={(e) => mouseMoveFunction(e, sliderRef)}
                onTouchStart={() => touchFunction(setRerender, rerender)}>
                {children}
            </div>
        </div>
    );
};

export default SliderRight;
