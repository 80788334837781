import React from "react";
import "./SliderCard.scss";
const SliderCard = ({ title, img, description, premium }) => {
    return (
        <div
            id="slider-card"
            className={`text-unselectable d-flex rounded-3 flex-column align-items-center justify-content-between position-relative`}>
            <img src={img} alt={title} className="text-unselectable img-fluid slider-img m-auto" />
            <div className="w-100 content-container p-4 d-flex align-items-center justify-content-start flex-column gap-3">
                <span className="text-unselectable color-main-black font-size-40 font-pnb line-height-13 text-center">
                    {title}
                </span>
                <span className="text-unselectable color-main-gray font-size-16 font-pnr line-height-13 text-center">
                    {description}
                </span>
            </div>
            {premium ? <div className="premium-card">{premium}</div> : null}
        </div>
    );
};

export default SliderCard;
