import React from "react";
import { Row, Col } from "react-bootstrap";
import { RiskIcon } from "../../Utils/risk";
import { ReactComponent as CheckGreen } from "../../Views/Homepage/CoverageBonds/Assets/check-green.svg";
import { ReactComponent as CheckOrange } from "../../Views/Homepage/CoverageBonds/Assets/check-orange.svg";
import { ReactComponent as CheckRed } from "../../Views/Homepage/CoverageBonds/Assets/check-red.svg";
import Button from "../Button/Button";

import "./CoverageCard.scss";

const CoverageCard = ({ img, title, props, description, pool, colors }) => {
    return (
        <div className="coverage-card w-100 gray-border my-3">
            <Row>
                <Col sm={12} lg={4} xl={3}>
                    <div className="d-flex align-items-center justify-content-center p-4 p-lg-5 h-100">
                        <img src={img} alt="coverage bond" className="img-fluid coverage-img" />
                    </div>
                </Col>
                <Col sm={12} lg={8} xl={5}>
                    <div className="d-flex align-items-start justify-content-start flex-column gap-3 p-4 p-lg-5">
                        <span className="font-size-32 font-pnb color-main-black line-height-13">
                            {title}
                        </span>
                        <div className="d-flex align-items-start justify-content-start gap-0 flex-wrap">
                            {props?.map((el, i) => {
                                return (
                                    <span
                                        key={"prop" + i}
                                        style={{ color: colors?.textColor }}
                                        className={`font-size-20 font-pnb d-flex align-items-center justify-content-center gap-1 me-3`}>
                                        {pool?.risk === "low" ? (
                                            <CheckGreen />
                                        ) : pool?.risk === "medium" ? (
                                            <CheckOrange />
                                        ) : pool?.risk === "high" ? (
                                            <CheckRed />
                                        ) : null}{" "}
                                        {el}
                                    </span>
                                );
                            })}
                        </div>
                        <span className="font-size-18 font-pnr color-main-gray line-height-14">
                            {description}
                        </span>

                        <div className="btns-container pt-3 d-flex flex-wrap align-items-start justify-content-start gap-3">
                            <Button
                                title="Learn more"
                                height={56}
                                heightResponsive={42}
                                outlineColor="#000"
                                color="#000"
                                bgColor="transparent"
                                hoverColor="white"
                                bgHoverColor="#000"
                                arrow="right"
                                customClass="rounded-3"
                            />
                            <Button
                                title="Buy coverage"
                                height={56}
                                heightResponsive={42}
                                outlineColor={colors?.mainColor}
                                color="#000"
                                bgColor={colors?.mainColor}
                                hoverColor="#000"
                                bgHoverColor="transpatent"
                                arrow="right"
                                customClass="rounded-3"
                            />
                        </div>
                    </div>
                </Col>
                <Col sm={12} lg={12} xl={4}>
                    <div
                        className={`p-4 p-lg-5 h-100 d-flex align-items-center justify-content-center ${
                            pool?.risk === "low"
                                ? "green-bg"
                                : pool?.risk === "medium"
                                ? "orange-bg"
                                : null
                        }`}>
                        <span
                            style={{ color: colors?.mainColor }}
                            className={`d-flex gap-2 align-items-center justify-content-center flex-column`}>
                            <RiskIcon
                                poolRisk={pool?.risk}
                                title={`${pool?.risk} RISK`}
                                lightColor={pool?.lightColor}
                                color={pool?.color}
                                className="d-flex align-items-center gap-2 px-2 rounded-2"
                            />

                            <span className="font-pnm font-size-16 line-height-13">
                                starting from
                            </span>
                            <span
                                className={`font-pnb font-size-80 line-height-13 gradient-${pool?.risk}`}>
                                {pool?.cost ? "$" + pool?.cost : null}
                            </span>
                            {/* <span className="font-pnb font-size-32 line-height-13">
                                Coming soon
                            </span> */}
                            <span
                                style={{ color: colors?.textColor }}
                                className="font-pnb font-size-20 line-height-13">
                                {pool?.costType}
                            </span>
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CoverageCard;
