import { ReactComponent as ActiveBondsGreen } from "./poolListAssets/green/active-bonds.svg";
import { ReactComponent as BackupFundsGreen } from "./poolListAssets/green/backup-funds.svg";
import { ReactComponent as ClaimedBondsGreen } from "./poolListAssets/green/claimed-bonds.svg";
import { ReactComponent as EnrolledWalletsGreen } from "./poolListAssets/green/enrolled-wallets.svg";
import { ReactComponent as FundsAvailableGreen } from "./poolListAssets/green/funds-available.svg";
import { ReactComponent as FundsLockedGreen } from "./poolListAssets/green/funds-locked.svg";
import { ReactComponent as InfoGreen } from "./poolListAssets/green/info.svg";
import { ReactComponent as TotalAssetsGreen } from "./poolListAssets/green/total-assets.svg";
import { ReactComponent as TotalBondsGreen } from "./poolListAssets/green/total-bonds.svg";
import { ReactComponent as TotalCommisionsGreen } from "./poolListAssets/green/total-commisions.svg";

import { ReactComponent as ActiveBondsOrange } from "./poolListAssets/orange/active-bonds.svg";
import { ReactComponent as BackupFundsOrange } from "./poolListAssets/orange/backup-funds.svg";
import { ReactComponent as ClaimedBondsOrange } from "./poolListAssets/orange/claimed-bonds.svg";
import { ReactComponent as EnrolledWalletsOrange } from "./poolListAssets/orange/enrolled-wallets.svg";
import { ReactComponent as FundsAvailableOrange } from "./poolListAssets/orange/funds-available.svg";
import { ReactComponent as FundsLockedOrange } from "./poolListAssets/orange/funds-locked.svg";
import { ReactComponent as InfoOrange } from "./poolListAssets/orange/info.svg";
import { ReactComponent as TotalAssetsOrange } from "./poolListAssets/orange/total-assets.svg";
import { ReactComponent as TotalBondsOrange } from "./poolListAssets/orange/total-bonds.svg";
import { ReactComponent as TotalCommisionsOrange } from "./poolListAssets/orange/total-commisions.svg";

import { ReactComponent as ActiveBondsRed } from "./poolListAssets/red/active-bonds.svg";
import { ReactComponent as BackupFundsRed } from "./poolListAssets/red/backup-funds.svg";
import { ReactComponent as ClaimedBondsRed } from "./poolListAssets/red/claimed-bonds.svg";
import { ReactComponent as EnrolledWalletsRed } from "./poolListAssets/red/enrolled-wallets.svg";
import { ReactComponent as FundsAvailableRed } from "./poolListAssets/red/funds-available.svg";
import { ReactComponent as FundsLockedRed } from "./poolListAssets/red/funds-locked.svg";
import { ReactComponent as InfoRed } from "./poolListAssets/red/info.svg";
import { ReactComponent as TotalAssetsRed } from "./poolListAssets/red/total-assets.svg";
import { ReactComponent as TotalBondsRed } from "./poolListAssets/red/total-bonds.svg";
import { ReactComponent as TotalCommisionsRed } from "./poolListAssets/red/total-commisions.svg";

import { ReactComponent as ActiveBondsBlue } from "./poolListAssets/blue/active-bonds.svg";
import { ReactComponent as BackupFundsBlue } from "./poolListAssets/blue/backup-funds.svg";
import { ReactComponent as ClaimedBondsBlue } from "./poolListAssets/blue/claimed-bonds.svg";
import { ReactComponent as EnrolledWalletsBlue } from "./poolListAssets/blue/enrolled-wallets.svg";
import { ReactComponent as FundsAvailableBlue } from "./poolListAssets/blue/funds-available.svg";
import { ReactComponent as FundsLockedBlue } from "./poolListAssets/blue/funds-locked.svg";
import { ReactComponent as InfoBlue } from "./poolListAssets/blue/info.svg";
import { ReactComponent as TotalAssetsBlue } from "./poolListAssets/blue/total-assets.svg";
import { ReactComponent as TotalBondsBlue } from "./poolListAssets/blue/total-bonds.svg";
import { ReactComponent as TotalCommisionsBlue } from "./poolListAssets/blue/total-commisions.svg";

import { ReactComponent as ActiveBondsPurple } from "./poolListAssets/purple/active-bonds.svg";
import { ReactComponent as BackupFundsPurple } from "./poolListAssets/purple/backup-funds.svg";
import { ReactComponent as ClaimedBondsPurple } from "./poolListAssets/purple/claimed-bonds.svg";
import { ReactComponent as EnrolledWalletsPurple } from "./poolListAssets/purple/enrolled-wallets.svg";
import { ReactComponent as FundsAvailablePurple } from "./poolListAssets/purple/funds-available.svg";
import { ReactComponent as FundsLockedPurple } from "./poolListAssets/purple/funds-locked.svg";
import { ReactComponent as InfoPurple } from "./poolListAssets/purple/info.svg";
import { ReactComponent as TotalAssetsPurple } from "./poolListAssets/purple/total-assets.svg";
import { ReactComponent as TotalBondsPurple } from "./poolListAssets/purple/total-bonds.svg";
import { ReactComponent as TotalCommisionsPurple } from "./poolListAssets/purple/total-commisions.svg";

export const assetsThemeColor = {
    green: {
        activeBonds: <ActiveBondsGreen />,
        backupFunds: <BackupFundsGreen />,
        claimedBonds: <ClaimedBondsGreen />,
        enrolledWallets: <EnrolledWalletsGreen />,
        fundsAvailable: <FundsAvailableGreen />,
        fundsLocked: <FundsLockedGreen />,
        info: <InfoGreen />,
        totalAssets: <TotalAssetsGreen />,
        totalBonds: <TotalBondsGreen />,
        totalCommisions: <TotalCommisionsGreen />
    },
    orange: {
        activeBonds: <ActiveBondsOrange />,
        backupFunds: <BackupFundsOrange />,
        claimedBonds: <ClaimedBondsOrange />,
        enrolledWallets: <EnrolledWalletsOrange />,
        fundsAvailable: <FundsAvailableOrange />,
        fundsLocked: <FundsLockedOrange />,
        info: <InfoOrange />,
        totalAssets: <TotalAssetsOrange />,
        totalBonds: <TotalBondsOrange />,
        totalCommisions: <TotalCommisionsOrange />
    },
    red: {
        activeBonds: <ActiveBondsRed />,
        backupFunds: <BackupFundsRed />,
        claimedBonds: <ClaimedBondsRed />,
        enrolledWallets: <EnrolledWalletsRed />,
        fundsAvailable: <FundsAvailableRed />,
        fundsLocked: <FundsLockedRed />,
        info: <InfoRed />,
        totalAssets: <TotalAssetsRed />,
        totalBonds: <TotalBondsRed />,
        totalCommisions: <TotalCommisionsRed />
    },
    blue: {
        activeBonds: <ActiveBondsBlue />,
        backupFunds: <BackupFundsBlue />,
        claimedBonds: <ClaimedBondsBlue />,
        enrolledWallets: <EnrolledWalletsBlue />,
        fundsAvailable: <FundsAvailableBlue />,
        fundsLocked: <FundsLockedBlue />,
        info: <InfoBlue />,
        totalAssets: <TotalAssetsBlue />,
        totalBonds: <TotalBondsBlue />,
        totalCommisions: <TotalCommisionsBlue />
    },
    purple: {
        activeBonds: <ActiveBondsPurple />,
        backupFunds: <BackupFundsPurple />,
        claimedBonds: <ClaimedBondsPurple />,
        enrolledWallets: <EnrolledWalletsPurple />,
        fundsAvailable: <FundsAvailablePurple />,
        fundsLocked: <FundsLockedPurple />,
        info: <InfoPurple />,
        totalAssets: <TotalAssetsPurple />,
        totalBonds: <TotalBondsPurple />,
        totalCommisions: <TotalCommisionsPurple />
    }
};
