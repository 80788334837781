import React from "react";
import "./Benefits.scss";
import Title from "../../../Components/Title/Title";
import UpperTitle from "../../../Components/UpperTitle/UpperTitle";
import BenefitCard from "./BenefitCard/BenefitCard";
import { Col, Row } from "react-bootstrap";
import SliderGrab from "../../../Components/SliderGrab/SliderGrab";
import { homepageFirstSectionRef } from "../../../Utils/Refs";

const benefitsData = [
    {
        benefit: "wand",
        title: "Easy & Fast",
        content:
            "Our platform offers a user-friendly interface and streamlined processes for effortless registration or bond claiming. With intuitive navigation, you can easily access and explore our services, ensuring a smooth experience tailored to your needs."
    },
    {
        benefit: "align",
        title: "Automatised",
        content:
            "With every step seamlessly streamlined and automated, you can effortlessly and efficiently navigate the registration or claim process."
    },
    {
        benefit: "transform",
        title: "Crypto assets protection",
        content:
            "We prioritize the safety and security of your digital investments, offering various bonds to safeguard against unauthorized access, theft, hacking attempts, and many more."
    },
    {
        benefit: "pallete",
        title: "Enhanced Privacy",
        content:
            "As an additional benefit, our platform prioritizes the protection of your privacy. We understand the importance of keeping your personal and financial information confidential in the crypto space."
    },
    {
        benefit: "math",
        title: "Personalised",
        content:
            "Personalized approach to safeguarding your crypto assets that comes with a tailored bond solution. You can now invest confidently, knowing that your assets are protected according to your specific needs."
    },
    {
        benefit: "cube",
        title: "Scalable",
        content:
            "As your crypto assets expand, our scalable protection measures seamlessly adjust to accommodate your changing requirements."
    }
];

const Benefits = () => {
    return (
        <section
            id="homepage-benefits"
            className="sections-spacing"
            ref={homepageFirstSectionRef.ref}>
            <div className="container-main">
                <UpperTitle
                    className="bg-main-black px-2 rounded-1 d-inline-block color-light-green mb-2"
                    title={"START WITH CONFIDENCE"}
                />
                <Title title={"Benefits"} punctuation={"."} color={"#323232"} spacing={"big"} />
                <p className="mb-3 paragraph-container font-size-18 font-pnr color-main-gray pe-3 line-height-14">
                    A platform that offers numerous advantages as a unique solution for protecting
                    your crypto assets and investments. With our personalized bond solutions,
                    scalable protection measures, and unwavering commitment to safety, we prioritize
                    the utmost security for your digital investments.
                </p>

                <div className="d-none d-lg-block">
                    <Row xs={2} sm={2} md={3} lg={3} className="g-5 mt-1">
                        {benefitsData.map((benefit, index) => {
                            return (
                                <Col key={index}>
                                    <BenefitCard
                                        benefit={benefit.benefit}
                                        title={benefit.title}
                                        content={benefit.content}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </div>

                <div className="d-block d-lg-none">
                    <SliderGrab>
                        {benefitsData.map((benefit, index) => {
                            return (
                                <BenefitCard
                                    key={index}
                                    benefit={benefit.benefit}
                                    title={benefit.title}
                                    content={benefit.content}
                                />
                            );
                        })}
                    </SliderGrab>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
