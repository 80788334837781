import React from 'react'
import Title from '../../../Components/Title/Title'
import UpperTitle from '../../../Components/UpperTitle/UpperTitle'
import { ReactComponent as ExchangeIco } from "../Assets/svg/exchange.svg"
import { ReactComponent as AmountIco } from "../Assets/svg/bigger-amount.svg"
import { ReactComponent as CoinIco } from "../Assets/svg/coin.svg"

import "./Missed.scss";
import Button from '../../../Components/Button/Button'
import { Col, Row } from 'react-bootstrap'

const Missed = () => {
    return (
        <section id="exchange-missed" className='sections-spacing'>
            <div className='container-main d-flex align-items-center justify-content-center flex-column'>
                <UpperTitle title={"HAVEN'T FOUND WHAT"} />
                <Title title={"You’re looking for"} punctuation={""} color={"#323232"} spacing={"big"} />


                <Row className='w-100 gy-3 gy-lg-0'>
                    <Col md={12} lg={4} className="col-padding">
                        <div className='h-100 bg-1 p-4 p-lg-5 d-flex align-items-center justify-content-between flex-column gap-4 gap-lg-5'>
                            <div className="svg-ico">
                                <ExchangeIco className="svg-ico" />
                            </div>
                            <span className='mb-auto font-size-48 font-pnb color-main-white text-center line-height-11'>
                                My exchange <br />
                                is not <br />
                                in the list.
                            </span>

                            <Button
                                title="Ask for quotation"
                                height={56}
                                heightResponsive={42}
                                heightResponsive580={42}
                                color="#FFFFFF"
                                outlineColor="#FFFFFF"
                                bgColor="transparent"
                                hoverColor="#12784A"
                                bgHoverColor="#FFFFFF"
                                arrow="right"
                            // specialPadding="0 2rem 0 2rem"
                            />
                        </div>
                    </Col>
                    <Col md={12} lg={4} className="col-padding">
                        <div className='h-100 bg-2 p-4 p-lg-5 d-flex align-items-center justify-content-between flex-column gap-4 gap-lg-5'>
                            <div className="svg-ico">
                                <AmountIco />
                            </div>
                            <span className='mb-auto font-size-48 font-pnb color-main-white text-center line-height-11'>
                                My amount <br />
                                <span className="color-light-green">is bigger <br />
                                    than 100K.</span>
                            </span>

                            <Button
                                title="Ask for quotation"
                                height={56}
                                heightResponsive={42}
                                heightResponsive580={42}
                                color="#FFFFFF"
                                outlineColor="#FFFFFF"
                                bgColor="transparent"
                                hoverColor="#12784A"
                                bgHoverColor="#FFFFFF"
                                arrow="right"
                            // specialPadding="0 2rem 0 2rem"
                            />
                        </div>
                    </Col>
                    <Col md={12} lg={4} className="col-padding">
                        <div className='h-100 bg-3 p-4 p-lg-5 d-flex align-items-center justify-content-between flex-column gap-4 gap-lg-5'>
                            <div className="svg-ico">
                                <CoinIco className="svg-ico" />
                            </div>
                            <span className='mb-auto font-size-48 font-pnb color-main-white text-center line-height-11'>
                                My coin <br />
                                is not <br />
                                in the list.
                            </span>

                            <Button
                                title="Ask for quotation"
                                height={56}
                                heightResponsive={42}
                                heightResponsive580={42}
                                color="#FFFFFF"
                                outlineColor="#FFFFFF"
                                bgColor="transparent"
                                hoverColor="#12784A"
                                bgHoverColor="#FFFFFF"
                                arrow="right"
                            // specialPadding="0 2rem 0 2rem"
                            />
                        </div>
                    </Col>
                </Row >
            </div >


        </section >
    )
}

export default Missed