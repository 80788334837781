import React from "react";
import "./HeroUpperTitle.scss";

const HeroUpperTitle = ({ title, ico, custom }) => {
    return (
        <div id="hero-uppertitle" className={`mb-2 rounded-3 ${custom ? "custom-blue" : ""}`}>
            <div
                className={`py-1 d-flex gap-2 align-items-center ${
                    custom ? "color-light-blue" : "color-light-green"
                } justify-content-center font-size-16 font-pnm text-unselectable `}>
                {ico && ico}
                {title}
            </div>
        </div>
    );
};

export default HeroUpperTitle;
