import { appRoutes } from "../../Utils/routes";

export const navbarRoutes = [
    {
        title: "Homepage",
        route: "/",
        submenu: false
    },
    {
        title: "Pools",
        route: appRoutes.pools,
        submenu: true,
        submenuItems: [
            // {
            //     title: "Standard pools",
            //     route: appRoutes.standardPools
            // },
            {
                title: "Custom pools",
                route: appRoutes.customPools
            }
        ]
    },
    // {
    //     title: "Products",
    //     route: [appRoutes.exchange],
    //     submenu: true,
    //     submenuItems: [
    //         {
    //             title: "Exchange cover",
    //             route: appRoutes.exchange
    //         }
    //     ]
    // },
    // {
    //     title: "Support",
    //     route: [appRoutes.howItWorksStandard, appRoutes.howItWorksCustom],
    //     submenu: true,
    //     submenuItems: [
    //         {
    //             title: "How it works - standard pools",
    //             route: appRoutes.howItWorksStandard
    //         },
    //         {
    //             title: "How it works - custom pools",
    //             route: appRoutes.howItWorksCustom
    //         }
    //     ]
    // }
];
