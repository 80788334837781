import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Title from '../../../Components/Title/Title';
import UpperTitle from '../../../Components/UpperTitle/UpperTitle';
import { ReactComponent as LowRiskIco } from "../../../Assets/svg/low-risk.svg"
import { ReactComponent as InfoGreen } from "../../../Components/PoolList/poolListAssets/green/info.svg"
import "./Packages.scss";
import Button from '../../../Components/Button/Button';

const Packages = () => {
    return (
        <section id="exchange-packages" className='sections-spacing'>
            <div className='container-main d-flex align-items-center justify-content-center flex-column'>
                <UpperTitle title={"EXCHANGE COVER"} />
                <Title title={"Packages"} punctuation={""} color={"#323232"} spacing={"big"} />

                <Row xs={1} sm={1} md={1} lg={3} >
                    <Col className='py-3 py-lg-0'>
                        <div className='w-100 p-3 p-lg-5 package-container gray-border d-flex align-items-center justify-content-between flex-column gap-3'>
                            <div className='risk-container color-main-green d-flex font-size-16 font-pnm align-items-center justify-content-center gap-2'>
                                <LowRiskIco />
                                LOW RISK
                            </div>
                            <span className='font-size-32 font-pnb color-main-black'>Package I</span>
                            <div className='d-flex align-items-center justify-content-center flex-column gap-0'>
                                <span className='color-main-gray font-size-28 font-pnb line-height-08'>2.121556</span>
                                <div className='color-main-gray font-size-12 font-pnm d-flex align-items-center justify-content-center gap-2'>
                                    Risk coefficient
                                    <InfoGreen />
                                </div>
                            </div>


                            <div className='d-flex align-items-center justify-content-cemnter flex-column w-100 gap-1'>
                                <div className='separator1 w-100'></div>
                                <div className='d-flex align-items-center justify-content-cemnter gap-1 py-2'>
                                    <span className='color-main-gray font-size-12 font-pnm'>up to</span>
                                    <span className='color-main-green font-size-24 font-pnb'>$10.000</span>
                                </div>
                                <div className='separator1 w-100'></div>
                            </div>


                            <div className='py-3 d-flex green-text-gradient align-items-end justify-content-cemnter gap-1'>
                                <span className='font-size-48 font-pnb line-height-08'>$8</span>
                                <span className='font-size-12 font-pnm'>per month</span>
                            </div>

                            <Button
                                title="Buy now"
                                height={52}
                                heightResponsive={42}
                                heightResponsive580={42}
                                color="#FFFFFF"
                                outlineColor="#12784A"
                                bgColor="#12784A"
                                hoverColor="#12784A"
                                bgHoverColor="#FFFFFF"
                                arrow="right"
                            />

                        </div>
                    </Col>

                    <Col className='py-3 py-lg-0'>
                        <div className='w-100 p-3 p-lg-5 package-container gray-border d-flex align-items-center justify-content-between flex-column gap-3'>
                            <div className='risk-container color-main-green d-flex font-size-16 font-pnm align-items-center justify-content-center gap-2'>
                                <LowRiskIco />
                                LOW RISK
                            </div>
                            <span className='font-size-32 font-pnb color-main-black'>Package II</span>
                            <div className='d-flex align-items-center justify-content-center flex-column gap-0'>
                                <span className='color-main-gray font-size-28 font-pnb line-height-08'>2.121556</span>
                                <div className='color-main-gray font-size-12 font-pnm d-flex align-items-center justify-content-center gap-2'>
                                    Risk coefficient
                                    <InfoGreen />
                                </div>
                            </div>


                            <div className='d-flex align-items-center justify-content-cemnter flex-column w-100 gap-1'>
                                <div className='separator1 w-100'></div>
                                <div className='d-flex align-items-center justify-content-cemnter gap-1 py-2'>
                                    <span className='color-main-gray font-size-12 font-pnm'>up to</span>
                                    <span className='color-main-green font-size-24 font-pnb'>$30.000</span>
                                </div>
                                <div className='separator1 w-100'></div>
                            </div>


                            <div className='py-3 d-flex green-text-gradient align-items-end justify-content-cemnter gap-1'>
                                <span className='font-size-48 font-pnb line-height-08'>$30</span>
                                <span className='font-size-12 font-pnm'>per month</span>
                            </div>

                            <Button
                                title="Buy now"
                                height={52}
                                heightResponsive={42}
                                heightResponsive580={42}
                                color="#FFFFFF"
                                outlineColor="#12784A"
                                bgColor="#12784A"
                                hoverColor="#12784A"
                                bgHoverColor="#FFFFFF"
                                arrow="right"
                            />

                        </div>
                    </Col>

                    <Col className='py-3 py-lg-0'>
                        <div className='w-100 p-3 p-lg-5 package-container gray-border d-flex align-items-center justify-content-between flex-column gap-3'>
                            <div className='risk-container color-main-green d-flex font-size-16 font-pnm align-items-center justify-content-center gap-2'>
                                <LowRiskIco />
                                LOW RISK
                            </div>
                            <span className='font-size-32 font-pnb color-main-black'>Package III</span>
                            <div className='d-flex align-items-center justify-content-center flex-column gap-0'>
                                <span className='color-main-gray font-size-28 font-pnb line-height-08'>2.121556</span>
                                <div className='color-main-gray font-size-12 font-pnm d-flex align-items-center justify-content-center gap-2'>
                                    Risk coefficient
                                    <InfoGreen />
                                </div>
                            </div>


                            <div className='d-flex align-items-center justify-content-cemnter flex-column w-100 gap-1'>
                                <div className='separator1 w-100'></div>
                                <div className='d-flex align-items-center justify-content-cemnter gap-1 py-2'>
                                    <span className='color-main-gray font-size-12 font-pnm'>up to</span>
                                    <span className='color-main-green font-size-24 font-pnb'>$100.000</span>
                                </div>
                                <div className='separator1 w-100'></div>
                            </div>


                            <div className='py-3 d-flex green-text-gradient align-items-end justify-content-cemnter gap-1'>
                                <span className='font-size-48 font-pnb line-height-08'>$120</span>
                                <span className='font-size-12 font-pnm'>per month</span>
                            </div>

                            <Button
                                title="Buy now"
                                height={52}
                                heightResponsive={42}
                                heightResponsive580={42}
                                color="#FFFFFF"
                                outlineColor="#12784A"
                                bgColor="#12784A"
                                hoverColor="#12784A"
                                bgHoverColor="#FFFFFF"
                                arrow="right"
                            />

                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Packages