import React from "react";
import "./RiskCards.scss";
import RiskCard from "./RiskCard/RiskCard";
import SliderGrab from "../../../Components/SliderGrab/SliderGrab";
// import Fade from 'react-reveal'

const RiskCards = () => {
    return (
        <section id="homepage-risk-cards" className="sections-spacing pt-0">
            {/* <div className="d-flex align-items-center justify-content-center h-100"></div> */}
            <SliderGrab>
                <div className="container-main d-flex w-100 align-items-center justify-content-between gap-4">
                    <RiskCard
                        title={"LOW Risk Pool"}
                        risk={"low"}
                        lightColor={"#02FDB1"}
                        color={"#12784A"}
                        delay={500}
                        totalAssets={"-"}
                    />

                    <RiskCard
                        title={"MEDIUM risk pool"}
                        risk={"medium"}
                        lightColor={"#805900"}
                        color={"#F8DD6C"}
                        delay={750}
                        totalAssets={"-"}
                    />
                    <RiskCard
                        title={"HIGH risk pool"}
                        risk={"high"}
                        lightColor={"#800000"}
                        color={"#F8826C"}
                        delay={1000}
                        totalAssets={"-"}
                    />
                    <RiskCard
                        title={"CUSTOM pool"}
                        risk={"shield"}
                        lightColor={"#760080"}
                        color={"#F4A2ED"}
                        delay={1250}
                        totalAssets={"1.440.123 EUR"}
                        url="/custom-pools"
                    />
                </div>
            </SliderGrab>
        </section>
    );
};

export default RiskCards;
