import SinglePoolHero from "./Components/SinglePoolHero";
import "./SinglePool.scss";
import { livepotData } from "./livepot.data";
import { ReactComponent as LowRiskIco } from "../../Assets/svg/low-risk.svg";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { RiDownloadCloudLine } from "react-icons/ri";
import livePotContract from "../../Assets/png/bank-letter.png";
// import livepotValues from "./export_valori_lipo.json";
import livepotValues from "./zixxar-bought.json";
import { Fragment, useCallback, useEffect, useState } from "react";
import { ReactComponent as User } from "./Assets/user.svg";
import guarantee from "./Assets/guarantee_bank.pdf";

const SinglePool = () => {
    const totalSold = livepotValues.reduce((acc, curr) => {
        return acc + curr.balance;
    }, 0);

    const alterIDs = livepotValues.map((item) => {
        return {
            accountId: "0x13" + item.accountId.replace(/-/g, ""),
            balance: item.balance - item.balance * (35 / 100)
        };
    });

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredResults, setFilteredResults] = useState(alterIDs);

    const setFilters = useCallback(() => {
        const filtered = alterIDs.filter((item) => item.accountId.includes(searchQuery));
        setFilteredResults(filtered);
    }, [searchQuery]);

    useEffect(() => {
        setFilters();
    }, [setFilters]);

    return (
        <section className="single-pool sections-spacing">
            <SinglePoolHero />

            <div className="container-main">
                <div className="row">
                    <div className="col-md-4 offset-md-4 col-12">
                        <div className="total-pool d-flex align-items-center justify-content-center radius-8 bg-light-green">
                            <span className="font-size-48 font-pnb">
                                {livepotData.totalSold.USD}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-main sections-spacing">
                <div className="gray-border mt-5">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8 col-12">
                            <div className="p-sm-5 p-4">
                                <span className="font-size-16 mb-3 d-block">
                                    Bank Guarantee is automatically renewed until 2025.
                                </span>
                                <div className="d-flex gap-4">
                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Risk coefficient
                                        </span>
                                        <div className="risk-coefficient d-flex align-items-center gap-2 px-3 py-1 radius-4">
                                            <LowRiskIco color="#12784A" />
                                            <span className="font-size-16">RISK: 1.5</span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Status
                                        </span>
                                        <div className="d-flex align-items-center gap-2 bg-light-green radius-4 px-3 py-1">
                                            <BsFillCheckCircleFill color="#0B482C" />
                                            <span className="font-size-16">Active</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex mt-4">
                                    <img width={247} src={livepotData.logo} alt="" />
                                </div>

                                <div className="d-flex flex-column gap-2 mt-4">
                                    <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                        Chain ID
                                    </span>
                                    <span className="chain-id font-size-16 d-sm-flex align-items-center justify-content-center px-3 radius-4">
                                        {livepotData.chainID}
                                    </span>
                                </div>

                                <div className="d-flex mt-4 gap-4">
                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Issue date
                                        </span>
                                        <div className="d-flex align-items-center gap-2 color-main-black">
                                            <BiTime color="#323232" size={25} />
                                            <span className="font-size-16">
                                                {livepotData.guarantee.bank1.issueDate}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Start date
                                        </span>
                                        <div className="d-flex align-items-center gap-2 color-main-black">
                                            <BiTime color="#1D98B5" size={25} />
                                            <span className="font-size-16">
                                                {livepotData.guarantee.bank1.startDate}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Expire date
                                        </span>
                                        <div className="d-flex align-items-center gap-2 color-main-black">
                                            <BiTime color="#EE523A" size={25} />
                                            <span className="font-size-16">
                                                {livepotData.guarantee.bank1.expireDate}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex mt-4 gap-4">
                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Beneficiary Representative
                                        </span>
                                        <div className="d-flex align-items-center gap-2 color-main-black">
                                            {livepotData.beneficiary.logo}
                                            <span className="font-size-16">
                                                {livepotData.beneficiary.name}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Issued by
                                        </span>
                                        <div className="d-flex align-items-center gap-2 color-main-black">
                                            {livepotData.issuedBy.logo}
                                            <span className="font-size-16">
                                                {livepotData.issuedBy.name}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Guarantee
                                        </span>
                                        <div className="d-flex align-items-center gap-2 color-main-black">
                                            {/* {livepotData.issuedBy.logo} */}
                                            <span className="font-size-16">
                                                {livepotData.guarantee.bank1.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-sm-flex mt-4 align-items-end justify-content-between">
                                    <div className="d-flex flex-column gap-2 justify-content-between">
                                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                            Beneficiary
                                        </span>
                                        <div className="d-flex align-items-center gap-2 bg-light-green radius-4 px-3 py-1 beneficiary-active">
                                            <BsFillCheckCircleFill color="#0B482C" />
                                            <span className="font-size-16">Active</span>
                                        </div>
                                    </div>

                                    <div className="d-flex mt-sm-0 mt-2 flex-column gap-2 justify-content-between">
                                        <input
                                            type="text"
                                            className="search-byUser"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row align-items-center mt-2 ms-0 me-0 table-head-search">
                                    <div className="col-8">
                                        <span className="font-size-16 font-pnb text-light">
                                            User ID
                                        </span>
                                    </div>
                                    <div className="col-4">
                                        <span className="font-size-16 font-pnb text-light">
                                            Amount
                                        </span>
                                    </div>
                                </div>

                                <div className="row align-items-center ms-0 me-0 table-body-search">
                                    {filteredResults.slice(0, 4).map((value) => (
                                        <Fragment key={value.accountId}>
                                            <div className="col-sm-8 col-9 my-sm-2 my-1">
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className="d-sm-inline-block d-none">
                                                        <User />
                                                    </div>
                                                    <span className="font-size-16 font-pn-medium color-main-gray">
                                                        {value.accountId}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-3 my-sm-2 my-1">
                                                <span className="font-size-16 font-pn-medium color-main-green">
                                                    {value.balance.toFixed(2)} USDT
                                                </span>
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5 col-lg-4 col-12">
                            <div className="gray-border position-relative">
                                <img className="img-fluid" src={livePotContract} alt="" />

                                <a
                                    href={guarantee}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="bg-light-green font-size-18 d-flex text-decoration-none text-dark lh-1 px-5 radius-8 py-4 position-absolute start-50 gap-3 align-items-center"
                                    style={{
                                        bottom: "20px",
                                        transform: "translateX(-50%)"
                                    }}>
                                    <RiDownloadCloudLine size={30} color="#000" />
                                    Download <br /> Bank Guarantee
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="gray-border p-4 p-lg-5 mt-5">
                    <h2 className="font-size-32 font-pnb mb-4">
                        {livepotData.poolInformation.name}
                    </h2>
                    <p className="font-size-18 color-main-gray mb-0">
                        {livepotData.poolInformation.description}
                    </p>

                    <div className="d-sm-flex flex-wrap gap-5 mt-5">
                        {livepotData.poolInformation.social.map((social) => (
                            <div
                                key={social.label}
                                className="d-flex flex-column gap-3 justify-content-between mb-sm-0 mb-4">
                                <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                    {social.label}
                                </span>
                                <a
                                    href={social.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="d-flex align-items-center gap-2 text-decoration-none">
                                    {social.icon}
                                    <span className="font-size-16 color-main-green">
                                        {social.urlLabel}
                                    </span>
                                </a>
                            </div>
                        ))}
                    </div>

                    <div className="mt-5">
                        <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                            Documents
                        </span>
                        <div className="d-sm-flex flex-wrap gap-4 mt-2">
                            {livepotData.poolInformation.documents.map((document) => (
                                <a
                                    href={document.pdf}
                                    key={document.label}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="d-flex mb-sm-0 mb-4 flex-column gap-2 gray-border px-5 py-4 align-items-center radius-8 text-decoration-none color-main-black">
                                    <RiDownloadCloudLine size={30} color="#12784A" />
                                    {document.label}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SinglePool;
