import Risks from "./Assets/risks.png";
import Broker from "./Assets/become-broker.png";
import Picture2 from "./Assets/products.png";
import Protection from "./Assets/invest.png";

export const sliderData = [
    {
        img: Risks,
        title: "Online support.",
        description: "Dedicated online support 24/7 for all the Martoms Brothers & Co. customers."
    },
    {
        img: Broker,
        title: "Phone support.",
        description: "Dedicated phone support for custom products customers."
    },
    {
        img: Protection,
        title: "Contact broker.",
        description:
            "Broker available for inquiries and clarifications about our products, claims, and bonds at any time and from anywhere.",
        premium: ["up to ", <span className="font-size-32 font-pnb">80%</span>, " commission"]
    },
    {
        img: Picture2,
        title: "Premium.",
        description: "Exclusive community protected against any type of cyber threats.",
        premium: ["up to ", <span className="font-size-32 font-pnb">35%</span>, " commission"]
    }
];
