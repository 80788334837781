import React from 'react'
import { Row, Col } from "react-bootstrap"
import Title from '../../../Components/Title/Title';
import UpperTitle from '../../../Components/UpperTitle/UpperTitle';
import StandardPoolsImg from "../../../Assets/png/standard-pools.png"
import "./StandardPools.scss";

const StandardPools = () => {
    return (
        <section id="pools-standard" className='sections-spacing'>
            <div className='container-main w-100'>

                <Row xs={1} sm={1} md={1} lg={2}>
                    <Col>
                        <div className='d-flex align-items-start justify-content-start flex-column gap-3 w-100 pe-5rem'>
                            <div className='d-flex align-items-tart justify-content-start flex-column gap-2'>

                                <UpperTitle title={"ABOUT"} color={true} />
                                <Title title={"Standard pools"} punctuation={"."} />
                            </div>
                            <span className='font-size-32 font-pnb line-height-13 color-main-gray'>
                                The risk is <span className='color-main-green'>predetermined</span> and the
                                Products are <span className='color-main-green'>calculated</span> based on the risk.
                            </span>
                            <span className='font-size-18 font-pnr color-main-gray'>
                                A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine.
                                <br />
                                <br />
                                A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine.
                            </span>
                        </div>
                    </Col>
                    <Col className='mt-4 mt-lg-0'>
                        <div className='d-flex align-items-center justify-content-center gray-border py-3 py-md-5'>
                            <img src={StandardPoolsImg} alt="standard pools" className='img-fluid standard-pools-img' />
                        </div>
                    </Col>
                </Row>

            </div>
        </section>
    )
}

export default StandardPools