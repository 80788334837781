import { livepotData } from "../livepot.data";

const SinglePoolHero = () => {
    return (
        <div
            className="hero-single-pool d-flex align-items-center justify-content-center flex-column"
            style={{
                background: `url(${livepotData.poolInformation.heroImage}) no-repeat center center / cover`
            }}>
            <h1 className="font-size-64 text-light font-pnb mb-0">Custom pool:</h1>
            <h1 className="font-size-64 color-light-green font-pnb">LivePot.com</h1>
        </div>
    );
};

export default SinglePoolHero;
