import React from 'react'
import Title from '../../../Components/Title/Title'
import UpperTitle from '../../../Components/UpperTitle/UpperTitle'
import { ReactComponent as LowRiskIco } from "../../../Assets/svg/low-risk.svg"
import { ReactComponent as MedRiskIco } from "../../../Assets/svg/med-risk.svg"
import { ReactComponent as HiRiskIco } from "../../../Assets/svg/high-risk.svg"
import { ReactComponent as ShieldIco } from "../../../Assets/svg/shield-plus.svg"

import "./Table.scss"

const RISKS = {
    low: <LowRiskIco />,
    med: <MedRiskIco />,
    hi: <HiRiskIco />,
    shield: <ShieldIco />
}

const COLORS = {
    low: "#3BBF83",
    med: "#F39B30",
    hi: "#EE4A59",
    shield: "#1D98B5"
}

const TITLES = {
    low: "LOW RISK POOL",
    med: "MEDIUM POOL",
    hi: "HIGH RISK POOL",
    shield: "CUSTOM POOL"
}

const dummyData = [{
    risk: "low",
    bonds: "Lorem ipsum dolor sit.",
    bondsId: "asdasd23312dasas",
    amount: "12.321",
    coin: "Bitcoin BTC",
    period: "23 Sep 2022 - 23 Sep 2023",
    status: "accepted"
},
{
    risk: "med",
    bonds: "Lorem ipsum dolor sit.",
    bondsId: "asdasd23312dasas",
    amount: "12.321",
    coin: "Bitcoin BTC",
    period: "23 Sep 2022 - 23 Sep 2023",
    status: "voting"
},
{
    risk: "hi",
    bonds: "Lorem ipsum dolor sit.",
    bondsId: "asdasd23312dasas",
    amount: "12.321",
    coin: "Bitcoin BTC",
    period: "23 Sep 2022 - 23 Sep 2023",
    status: "denied"
},
{
    risk: "shield",
    bonds: "Lorem ipsum dolor sit.",
    bondsId: "asdasd23312dasas",
    amount: "12.321",
    coin: "Bitcoin BTC",
    period: "23 Sep 2022 - 23 Sep 2023",
    status: "denied"
},

]

const Table = () => {

    const getRiskIco = (risk) => {
        if (Object.keys(RISKS).includes(risk)) return RISKS[risk];
        return "";
    }

    const getColor = (risk) => {
        if (Object.keys(COLORS).includes(risk)) return COLORS[risk];
        return "#000000";
    }

    const getTitle = (risk) => {
        if (Object.keys(TITLES).includes(risk)) return TITLES[risk];
        return "#000000";
    }

    return (
        <section id="pools-standard-list-detailed" className='sections-spacing'>
            <div className='container-main'>
                <UpperTitle title={"DETAILED LIST"} />
                <Title title={"Standard pools"} punctuation={"."} color={"#323232"} spacing={"big"} />
                <p className='mb-3 paragraph-container font-size-18 font-pnr color-main-gray pe-3 line-height-14'>
                    A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring.
                </p>

                <div className="overflow-table gray-border px-0">

                    <table className='w-100'>

                        <thead>
                            <tr>
                                <th className='font-size-18 font-pnb color-white risk-col'>
                                    Pool risk
                                </th>
                                <th className='font-size-18 font-pnb color-white bonds-col'>
                                    Bonds
                                </th>
                                <th className='font-size-18 font-pnb color-white bonds-id-col'>
                                    Bonds ID
                                </th>
                                <th className='font-size-18 font-pnb color-white amount-col'>
                                    Amount
                                </th>
                                <th className='font-size-18 font-pnb color-white coin-col'>
                                    Coin
                                </th>
                                <th className='font-size-18 font-pnb color-white period-col'>
                                    Period
                                </th>
                                <th className='font-size-18 font-pnb color-white status-col'>
                                    Status
                                </th>
                                <th className='font-size-18 font-pnb color-white actions-col'>
                                    Actions
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                dummyData.map((el, i) => {
                                    return <tr key={i}>
                                        <td>
                                            <div className={`risk-title-container font-size-16 text-nowrap font-pnm py-1 text-unselectable`} style={{
                                                color: `${getColor(el.risk)}`,
                                                backgroundColor: `transparent`,
                                                border: `1px solid ${getColor(el.risk)}`
                                            }}>
                                                {getRiskIco(el.risk)}
                                                {getTitle(el.risk)}
                                            </div>
                                        </td>
                                        <td>
                                            {el.bonds}
                                        </td>
                                        <td>
                                            {el.bondsId}
                                        </td>
                                        <td>
                                            {el.amount}
                                        </td>
                                        <td>
                                            {el.coin}
                                        </td>
                                        <td>
                                            {el.period}
                                        </td>
                                        <td>
                                            <div className={`px-3 font-size-16 font-pnm d-flex align-items-center justify-content-center card-${el.status}`}>
                                                {el.status}
                                            </div>
                                        </td>
                                        <td>
                                            ...
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>

                    </table>
                </div>
            </div>
        </section>

    )
}

export default Table