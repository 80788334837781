import React from 'react'
import { Helmet } from 'react-helmet';
import "./Exchange.scss";
import Exchanges from './Exchanges/Exchanges';
import FAQ from './FAQ/FAQ';
import Hero from './Hero/Hero';
import HeroCards from './HeroCards/HeroCards';
import Missed from './Missed/Missed';
import Packages from './Packages/Packages';
import Steps from './Steps/Steps';

const Exchange = () => {
    return (
        <section id="exchange">

            <Helmet>
                <title>MB&#38;CO | Exchange</title>
            </Helmet>

            <Hero />
            <HeroCards />
            <Packages />
            <Exchanges />
            <Missed />
            <Steps />
            <FAQ />


        </section>
    )
}

export default Exchange