import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../Components/Button/Button';
import Title from '../../../../Components/Title/Title'
import UpperTitle from '../../../../Components/UpperTitle/UpperTitle'
import { howItWorksStandardRefSection1 } from '../../../../Utils/Refs';
import DangersImg from "../../Assets/dangers-green.png";
import { ReactComponent as Icon1 } from "../../Assets/deposit1.svg"
import { ReactComponent as Icon2 } from "../../Assets/react1.svg"
import { ReactComponent as Icon3 } from "../../Assets/pie1.svg"
import { ReactComponent as Icon4 } from "../../Assets/green-lock-1.svg"

import "./InfoSection.scss";

const InfoSection = () => {
    return (
        <section id="how-it-works-standard-info" className='sections-spacing' ref={howItWorksStandardRefSection1.ref}>
            <div className='container-main d-flex align-items-center justify-content-center flex-column'>
                <UpperTitle title={"STANDARD POOLS"} />
                <Title title={"How it works"} punctuation={"."} color={"#323232"} spacing={"big"} />

                <Row >
                    <Col xs={12} sm={6} md={6} lg={3} className="order-2 order-lg-1">
                        <div className='d-flex flex-column align-items-start justify-content-between h-100 py-0 py-lg-4 gap-4'>
                            <div className='w-100 d-flex flex-column align-items-lg-end align-items-start justify-content-center gap-2'>

                                <Icon1 />

                                <span className='font-size-24 font-pnb text-lg-end text-start color-main-black line-height-12'>How to make a deposit?</span>
                                <span className='font-size-16 font-pnr text-lg-end text-start color-main-gray'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot. </span>

                                <Button
                                    title={"Learn more"}
                                    arrow={"right"}
                                    height={28}
                                    heightResponsive={28}
                                    heightResponsive580={28}
                                    color={"#12784A"}
                                    outlineColor={"transparent"}
                                    bgColor={"transparent"}
                                    hoverColor={"#000000"}
                                    align={"start"}
                                    specialPadding={"0px"}
                                    customClass="align-items-end justify-content-end"
                                />
                            </div>
                            <div className='w-100  d-flex flex-column align-items-lg-end align-items-start justify-content-center gap-2'>

                                <Icon2 />

                                <span className='font-size-24 font-pnb text-lg-end text-start color-main-black line-height-12'>How are the funds managed?</span>
                                <span className='font-size-16 font-pnr text-lg-end text-start color-main-gray'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot. </span>

                                <Button
                                    title={"Learn more"}
                                    arrow={"right"}
                                    height={28}
                                    heightResponsive={28}
                                    heightResponsive580={28}
                                    color={"#12784A"}
                                    outlineColor={"transparent"}
                                    bgColor={"transparent"}
                                    hoverColor={"#000000"}
                                    align={"start"}
                                    specialPadding={"0px"}
                                    customClass="align-items-end justify-content-end"
                                />
                            </div>
                        </div>
                    </Col>

                    <Col md={12} lg={6} className="order-1 order-lg-2 mb-4 mb-lg-0">
                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                            <img src={DangersImg} alt="dangers" className='img-fluid big-img m-auto' />
                        </div>
                    </Col>

                    <Col xs={12} sm={6} md={6} lg={3} className="order-3 order-lg-3">
                        <div className='d-flex flex-column align-items-start justify-content-between h-100 py-0 py-lg-4 gap-4'>
                            <div className='w-100 flex-column d-flex align-items-start justify-content-center gap-2 margin-top'>
                                <Icon3 />

                                <span className='font-size-24 font-pnb color-main-black line-height-12'>How are the funds executed?</span>
                                <span className='font-size-16 font-pnr color-main-gray'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot. </span>

                                <Button
                                    title={"Learn more"}
                                    arrow={"right"}
                                    height={28}
                                    heightResponsive={28}
                                    heightResponsive580={28}
                                    color={"#12784A"}
                                    outlineColor={"transparent"}
                                    bgColor={"transparent"}
                                    hoverColor={"#000000"}
                                    align={"start"}
                                    specialPadding={"0px"}
                                    customClass="align-items-start justify-content-start"
                                />
                            </div>
                            <div className='w-100 flex-column d-flex align-items-start justify-content-center gap-2'>
                                <Icon4 />

                                <span className='font-size-24 font-pnb color-main-black line-height-12'>How are the funds unlocked?</span>
                                <span className='font-size-16 font-pnr color-main-gray'>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot. </span>

                                <Button
                                    title={"Learn more"}
                                    arrow={"right"}
                                    height={28}
                                    heightResponsive={28}
                                    heightResponsive580={28}
                                    color={"#12784A"}
                                    outlineColor={"transparent"}
                                    bgColor={"transparent"}
                                    hoverColor={"#000000"}
                                    align={"start"}
                                    specialPadding={"0px"}
                                    customClass="align-items-start justify-content-start"
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>

    )
}

export default InfoSection