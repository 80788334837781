import React from 'react'
import "./Hero.scss";
import HeroUpperTitle from '../../../Components/HeroUpperTitle/HeroUpperTitle';
import { ReactComponent as ShieldIco } from "../../../Assets/svg/shield-check.svg";
import { Row, Col } from "react-bootstrap"
import Title from '../../../Components/Title/Title';
import Button from '../../../Components/Button/Button';
import ShieldImg from "../Assets/exchange-hero.png";

const Hero = () => {

    return (
        <section id="exchange-hero">
            <div className='container-main overflow-hidden'>

                <Row xs={1} sm={1} md={2} lg={2} className="h-100">
                    <Col className="h-100 order-2 order-md-1">
                        <div className='h-100 d-flex align-items-start justify-content-center flex-column'>
                            <HeroUpperTitle title={"EXCHANGE ASSETS THEFT"} ico={<ShieldIco />} />
                            <Title title={"Exchange cover"} punctuation={"."} color={"#FFFFFF"} />
                            <p className='paragraph-container font-size-18 font-pnr line-height-12 color-main-white mb-2 '>
                                A wonderful serenity has taken possession of my entire soul,
                                like these sweet mornings of spring which I enjoy with my whole heart.
                                I am alone, and feel the charm of existence in this spot, which was created
                                for the bliss of souls like mine.
                            </p>
                            <div className='mb-4 d-flex align-items-start justify-content-start gap-3 gap-lg-4 flex-column flex-xs-row mt-5'>
                                <Button
                                    title={"Learn more"}
                                    height={48}
                                    heightResponsive={42}
                                    color={"#FFFFFF"}
                                    outlineColor={"#FFFFFF"}
                                    hoverColor={"#000000"}
                                    bgColor={"transparent"}
                                    bgHoverColor={"#FFFFFF"}
                                    arrow={"right"}
                                />

                            </div>

                        </div>


                    </Col>

                    <Col className="d-flex  order-1 order-md-2 mb-3 mb-md-0">
                        <img src={ShieldImg} alt="shield" className="img-fluid hero-shield m-auto" />
                    </Col>
                </Row>
            </div>

        </section>
    )
}

export default Hero