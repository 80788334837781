import React from 'react'
import Title from '../../../Components/Title/Title';
import UpperTitle from '../../../Components/UpperTitle/UpperTitle';
import CoveredImg from "../Assets/check-assets-covered.png"
import CreateAccountImg from "../Assets/create-account.png"
import ExchangeAccountImg from "../Assets/exchange-account.png"
import RightArrowImg from "../Assets/right-side-index.png"
import LeftArrowImg from "../Assets/left-side-index.png"
import MobileArrowImg from "../Assets/vertical-index.png"
import PayBondImg from "../Assets/pay-bond.png"
import PressBuyImg from "../Assets/press-buy.png"
import PeriodImg from "../Assets/select-period.png"
import SubmitImg from "../Assets/submit-proceed.png"
import SliderGrab from "../../../Components/SliderGrab/SliderGrab"

import "./Steps.scss";

const STEPS = [
    {
        id: "1",
        bullets: [],
        title: <span className='font-size-24 font-pnb color-main-black'>Create your account.</span>,
        description: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot.",
        img: CreateAccountImg
    },
    {
        id: "2",
        bullets: [],
        title: <span className='font-size-24 font-pnb color-main-black'>Press <span className="color-main-green">“Buy”</span> in your dashboard.</span>,
        description: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot.",
        img: PressBuyImg
    },
    {
        id: "3",
        bullets: ["select your exchange from the list", "select your coin from the list"],
        title: <span className='font-size-24 font-pnb color-main-black'>Add your exchange account.</span>,
        description: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot.",
        img: ExchangeAccountImg
    },
    {
        id: "4",
        bullets: [],
        title: <span className='font-size-24 font-pnb color-main-black'>Check assets covered by this product.</span>,
        description: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot.",
        img: CoveredImg
    },
    {
        id: "5",
        bullets: [],
        title: <span className='font-size-24 font-pnb color-main-black'>Select the period needed for the bond.</span>,
        description: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot.",
        img: PeriodImg
    },
    {
        id: "6",
        bullets: [],
        title: <span className='font-size-24 font-pnb color-main-black'>Press <span className="color-main-green">“Submit”</span> button to proceed.</span>,
        description: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot.",
        img: SubmitImg
    },
    {
        id: "7",
        bullets: [],
        title: <span className='font-size-24 font-pnb color-main-black'>Pay for the bond in your favorite currency.</span>,
        description: "A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in spot.",
        img: PayBondImg
    },
]

const Steps = () => {
    return (
        <section id="exchange-steps" className='sections-spacing'>
            <div className='container-main d-flex align-items-start justify-content-start w-100 flex-column'>
                <UpperTitle title={"HOW TO"} />
                <Title title={"Generate a policy"} punctuation={"."} color={"#323232"} spacing={"big"} />

                <div className='w-100 steps-container desktop-container d-none d-lg-flex align-items-center justify-content-center flex-column gap-5'>
                    {
                        STEPS.map((step, i) => {
                            return (
                                <div key={"Step" + i} className={`individual-step position-relative w-100 d-flex ${i % 2 ? "flex-row" : "flex-row-reverse"}`}>
                                    <div className={`w-50 d-flex align-items-center justify-content-between ${i % 2 ? "flex-row-reverse" : "flex-row"}`}>
                                        {
                                            i % 2 === 0 ?
                                                <img src={RightArrowImg} alt="pointer" className='img-fluid' />
                                                :
                                                <img src={LeftArrowImg} alt="pointer" className='img-fluid' />
                                        }
                                        {i === 0 ? <div className='helper-block-start'></div> : null}
                                        {i === STEPS.length - 1 ? <div className='helper-block-end'></div> : null}

                                        <div className={`d-flex align-items-center justify-content-center gray-border p-5 w-100 ${i % 2 ? "me-5" : "ms-5"}`}>
                                            <img src={step.img} alt="step" className='img-fluid step-img' />
                                        </div>

                                    </div>
                                    <div className='w-50 d-flex'>
                                        <div className={`m-auto d-flex flex-column align-items-start justyfy-content-around p-5 ${i % 2 ? "ps-special" : "ps-0"}`}>

                                            <div className='d-flex align-items-start justify-content-start gap-3 flex-column'>
                                                <span className='font-size-16 font-pnm color-main-green line-height-1'>STEP 0{step.id}</span>
                                                <span className='font-size-24 font-pnb color-main-black line-height-1'>{step.title}</span>
                                                {
                                                    step.bullets.length > 0 ?
                                                        <div className='d-flex align-items-start justify-content-start gap-0 flex-column'>
                                                            {
                                                                step.bullets.map((el, idx) => {
                                                                    return (
                                                                        <div key={"bullet-" + idx} className='bullet-info d-flex align-items-center justify-content-start gap-2'>
                                                                            <div className='bullet'></div>
                                                                            <span className='font-size-18 font-pnm color-fade-green'>{el}</span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <span className='mt-5 font-size-16 font-pnr color-main-gray line-height-13'>{step.description}</span>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>

                {/* MOBILE */}

                <div className='w-100 mobile-container d-block d-lg-none '>
                    <SliderGrab>
                        <div className='d-flex text-unselectable align-items-start justify-content-start gap-5 top-bar'>
                            {
                                STEPS.map((step, i) => {
                                    return (
                                        <div key={"mobile-step" + i} className='d-flex mobile-step align-items-start h-100 justify-content-start'>
                                            <img src={MobileArrowImg} alt="pointer" className='img-fluid me-3' />
                                            <div className='d-flex align-items-start justify-content-center flex-column gap-2 mt-3'>
                                                <div className={`d-flex align-items-center justify-content-center gray-border p-3 w-100 mb-2`}>
                                                    <img src={step.img} alt="step" className='img-fluid step-img' />
                                                </div>
                                                <div className='d-flex align-items-start justify-content-start gap-3 flex-column'>
                                                    <span className='font-size-16 font-pnm color-main-green line-height-1'>STEP 0{step.id}</span>
                                                    <span className='font-size-24 font-pnb color-main-black line-height-1'>{step.title}</span>
                                                    {
                                                        step.bullets.length > 0 ?
                                                            <div className='d-flex align-items-start justify-content-start gap-0 flex-column'>
                                                                {
                                                                    step.bullets.map((el, idx) => {
                                                                        return (
                                                                            <div key={"bullet-" + idx} className='bullet-info d-flex align-items-center justify-content-start gap-2'>
                                                                                <div className='bullet'></div>
                                                                                <span className='font-size-18 font-pnm color-fade-green'>{el}</span>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <span className='mt-auto font-size-16 font-pnr color-main-gray line-height-13'>{step.description}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div style={{ opacity: 0 }}>.</div>
                        </div>
                    </SliderGrab>
                </div>
            </div>
        </section>
    )
}

export default Steps