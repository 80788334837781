import React from 'react'
import { Helmet } from 'react-helmet'
import Hero from './Hero/Hero'
import InfoSection from './InfoSection/InfoSection'

const HowItWorksStandard = () => {
    return (
        <section id="how-it-works-custom">

            <Helmet>
                <title>MB&#38;CO | How it works</title>
            </Helmet>

            <Hero />
            <InfoSection />


        </section>
    )
}

export default HowItWorksStandard