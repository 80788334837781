import React from "react";
import Helmet from "react-helmet";
import AsSeen from "./AsSeen/AsSeen";
import Benefits from "./Benefits/Benefits";
import CoverageBonds from "./CoverageBonds/CoverageBonds";
import CryptoAssets from "./CryptoAssets/CryptoAssets";
import GotYouCovered from "./GotYouCovered/GotYouCovered";
import Hero from "./Hero/Hero";
import HowItWorks from "./HowItWorks/HowItWorks";
import OurProducts from "./OurProducts/OurProducts";
import RiskCards from "./RiskCards/RiskCards";
import WhyChoose from "./WhyChoose/WhyChoose";

const Homepage = () => {
    return (
        <section id="homepage">
            <Helmet>
                <title>MB&#38;CO | Homepage</title>
            </Helmet>

            <Hero />
            <RiskCards />
            <CoverageBonds />
            <HowItWorks />
            {/* <OurProducts /> */}
            <GotYouCovered />
            <Benefits />
            <WhyChoose />
            <CryptoAssets />
            <AsSeen />
        </section>
    );
};

export default Homepage;
