export const appRoutes = {
    home: "/",
    products: "/products",

    pools: "/pools",
    standardPools: "/pools/standard-pools",

    customPools: "/custom-pools",
    customPoolsId: "/custom-pools/:id",

    exchange: "/exchange",
    howItWorksCustom: "/how-it-works-custom",
    howItWorksStandard: "/how-it-works-standard",
    notFound: "*"
};
