import React from 'react'
import Title from '../../../Components/Title/Title'
import UpperTitle from '../../../Components/UpperTitle/UpperTitle'
import { ReactComponent as RippleIco } from "../Assets/svg/RIPPLE.svg"
import { ReactComponent as BinanceIco } from "../Assets/svg/binance.svg"
import { ReactComponent as BTCIco } from "../Assets/svg/BTC.svg"
import { ReactComponent as CGIco } from "../Assets/svg/cg2.svg"
import { ReactComponent as CMCIco } from "../Assets/svg/cmc.svg"
import { ReactComponent as CoinbaseIco } from "../Assets/svg/coinbase.svg"
import { ReactComponent as CryptoComIco } from "../Assets/svg/cryptocom.svg"
import { ReactComponent as ETHIco } from "../Assets/svg/ETH.svg"
import { ReactComponent as EtoroIco } from "../Assets/svg/etoro.svg"
import { ReactComponent as GeminiIco } from "../Assets/svg/gemini.svg"
import { ReactComponent as KeakenIco } from "../Assets/svg/kraken.svg"
import { ReactComponent as KucoinIco } from "../Assets/svg/kucoin.svg"
import { ReactComponent as MessageIco } from "../Assets/svg/message.svg"
import { ReactComponent as SolIco } from "../Assets/svg/SOL.svg"
import { ReactComponent as UpholdIco } from "../Assets/svg/uphold.svg"
import { ReactComponent as USDTIco } from "../Assets/svg/USDT.svg"
import { ReactComponent as WhitebitIco } from "../Assets/svg/whitebit.svg"
import { ReactComponent as WorldIco } from "../Assets/svg/world.svg"
import { ReactComponent as BitstampIco } from "../Assets/svg/bitstamp.svg"

import "./Exchanges.scss";

const dummyDataIcons = {
    "Binance": <BinanceIco />,
    "Crypto.com": <CryptoComIco />,
    "eToro": <EtoroIco />,
    "Kraken": <KeakenIco />,
    "Gemini": <GeminiIco />,
    "WhiteBIT": <WhitebitIco />,
    "Coinbase": <CoinbaseIco />,
    "KuCoin": <KucoinIco />,
    "Uphold": <UpholdIco />,
    "Bitstamp": <BitstampIco />

}

const dummyData = [
    {
        exchange: "Binance",
        cmc: 1,
        cg: 1,
        coins: "+150 more",
    },
    {
        exchange: "Crypto.com",
        cmc: 2,
        cg: 2,
        coins: "+120 more",
    },
    {
        exchange: "eToro",
        cmc: 3,
        cg: 3,
        coins: "+105 more",
    },
    {
        exchange: "Kraken",
        cmc: 4,
        cg: 4,
        coins: "+105 more",
    },
    {
        exchange: "Gemini",
        cmc: 5,
        cg: 5,
        coins: "+85 more",
    },
    {
        exchange: "WhiteBIT",
        cmc: 6,
        cg: 6,
        coins: "+86 more",
    },
    {
        exchange: "Coinbase",
        cmc: 7,
        cg: 7,
        coins: "+68 more",
    },
    {
        exchange: "KuCoin",
        cmc: 8,
        cg: 8,
        coins: "+115 more",
    },
    {
        exchange: "Uphold",
        cmc: 9,
        cg: 9,
        coins: "+39 more",
    },
    {
        exchange: "Bitstamp",
        cmc: 10,
        cg: 10,
        coins: "+72 more",
    },
]



const Exchanges = () => {
    return (
        <section id="exchange-exchanges" className='sections-spacing'>
            <div className='container-main d-flex align-items-center justify-content-center flex-column'>
                <UpperTitle title={"WE COVER THE FOLLOWING"} />
                <Title title={"Assets & Exchanges"} punctuation={""} color={"#323232"} spacing={"big"} />


                <div className="overflow-table gray-border px-0 w-100">

                    <table className='w-100'>

                        <thead>
                            <tr>
                                <th className='font-size-18 font-pnb color-white exchanges-col'>
                                    Exchanges
                                </th>
                                <th className='font-size-18 font-pnb color-white small-col'>
                                    <div className='d-flex align-items-centre justify-content-center gap-2 '>
                                        <CMCIco /> CMC
                                    </div>
                                </th>
                                <th className='font-size-18 font-pnb color-white small-col2'>
                                    <div className='d-flex align-items-centre justify-content-start gap-2 '>
                                        <CGIco /> CG
                                    </div>
                                </th>
                                <th className='font-size-18 font-pnb color-white coins-col'>
                                    Coins
                                </th>
                                <th className='text-center font-size-18 font-pnb color-white details-col'>
                                    Details
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                dummyData.map((el, i) => {
                                    return <tr key={i}>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-start gap-2'>
                                                {dummyDataIcons[el.exchange]}
                                                {el.exchange}
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            #{el.cmc}
                                        </td>
                                        <td className='text-start'>
                                            #{el.cg}
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-start gap-1'>
                                                <BTCIco />
                                                <ETHIco />
                                                <USDTIco />
                                                <SolIco />
                                                <RippleIco />
                                                {el.coins}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center gap-3'>
                                                <WorldIco />
                                                <MessageIco />
                                            </div>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>

                    </table>
                </div>


            </div>
        </section>
    )
}

export default Exchanges