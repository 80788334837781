export const detectEnd = (atEnd, setAtEnd, setAtStart, sliderRef) => {
    let scrollLeft = sliderRef.current.scrollLeft
    const width = sliderRef.current.scrollWidth
    if (sliderRef.current.offsetWidth + scrollLeft >= width) {
        if (atEnd === false) {
            setAtEnd(true)
            setAtStart(false);
        }
        return true
    }
    if (scrollLeft > 0) {
        setAtStart(false)
    }
    if (atEnd === true) setAtEnd(false)

    return false;

}

export const detectStart = (atStart, setAtStart, setAtEnd, sliderRef) => {
    let scrollLeft = sliderRef.current.scrollLeft
    const width = sliderRef.current.scrollWidth
    if (scrollLeft <= 0) {
        if (atStart === false) {
            setAtStart(true)
            setAtEnd(false)
        }
        return true;
    }
    if (sliderRef.current.offsetWidth + scrollLeft < width) {
        setAtEnd(false)
    }
    if (atStart === true) setAtStart(false)

    return false
}