import React from 'react'
import PoolList from '../../../Components/PoolList/PoolList'
import Title from '../../../Components/Title/Title'
import UpperTitle from '../../../Components/UpperTitle/UpperTitle'
import "./CustomList.scss"

const CustomList = () => {
    return (
        <section id="pools-custom-list" className='sections-spacing'>
            <div className='container-main'>
                <UpperTitle title={"LISTS"} />
                <Title title={"Custom pools"} punctuation={"."} color={"#323232"} spacing={"big"} />

                <PoolList
                    theme="blue"
                    risk="shield"
                    riskTitle="LOW RISK POOL"
                    bigNumber="2.121556"
                />
                <PoolList
                    theme="blue"
                    risk="shield"
                    riskTitle="MEDIUM RISK POOL"
                    bigNumber="2.121556"
                />

            </div>
        </section>
    )
}

export default CustomList