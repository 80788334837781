import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        const currentURL = window.location.href;
        const result = currentURL.includes("#");

        if (!result) window.scrollTo(0, 0);
        else return;
    }, [pathname]);

    return null;
}
