import React from "react";
import "./AsSeen.scss";
import Title from "../../../Components/Title/Title";
import UpperTitle from "../../../Components/UpperTitle/UpperTitle";
import { ReactComponent as BitcoinLogo } from "../../../Assets/svg/bitcoin-com-logo.svg";
import { ReactComponent as CmcLogo } from "../../../Assets/svg/cmc-logo.svg";
import { ReactComponent as CoinbaseLogo } from "../../../Assets/svg/coinbase-logo.svg";
import { ReactComponent as CoindeskLogo } from "../../../Assets/svg/coindesk-logo.svg";
import { ReactComponent as FinboldLogo } from "../../../Assets/svg/finbold-logo.svg";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

const AsSeen = () => {
    return (
        <section id="homepage-as-seen" className="sections-spacing">
            <div className="container-main">
                <UpperTitle
                    className="bg-main-black px-2 rounded-1 d-inline-block color-light-green mb-2"
                    title={"FEATURED"}
                />
                <Title title={"As seen on"} punctuation={":"} color={"#323232"} spacing={"big"} />

                <Row xs={2} sm={2} md={4} lg={4} className="gx-3 gx-sm-4">
                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <CoindeskLogo className="img-fluid" />
                        </div>
                    </Col>

                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <BitcoinLogo className="img-fluid" />
                        </div>
                    </Col>

                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <FinboldLogo className="img-fluid" />
                        </div>
                    </Col>

                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <CmcLogo className="img-fluid" />
                        </div>
                    </Col>
                </Row>

                <Row xs={2} sm={2} md={4} lg={4} className="mt-0 mt-md-4  gx-3 gx-sm-4">
                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <CoinbaseLogo className="img-fluid" />
                        </div>
                    </Col>

                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <FinboldLogo className="img-fluid" />
                        </div>
                    </Col>

                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <CoindeskLogo className="img-fluid" />
                        </div>
                    </Col>

                    <Col className=" mb-3 mb-sm-4 mb-md-0">
                        <div
                            onClick={() =>
                                toast.info("Coming soon.", { toastId: "coming-soon-as-seen" })
                            }
                            className="individual-logo d-flex align-items-center justify-content-center gray-border p-cards">
                            <BitcoinLogo className="img-fluid" />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default AsSeen;
