import React from "react";

const PoolDataRow = ({ ico, title, value, className }) => {
    return (
        <div
            className={`${
                className ? className : "d-flex"
            } align-items-center justify-content-start gap-3 pool-data-row py-2 py-lg-0`}>
            {ico}
            <div className="d-flex align-items-start justify-content-between flex-column gap-3">
                <span className="font-size-16 font-pnm line-height-1 color-main-gray">{title}</span>
                <span className="font-size-40-custom font-pnb line-height-1 color-main-black">
                    {value}
                </span>
            </div>
        </div>
    );
};

export default PoolDataRow;
