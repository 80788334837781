import React, { useCallback, useEffect, useRef, useState } from "react";
import "./NavBar.scss";
import { ReactComponent as MainLogo } from "../../Assets/svg/main-logo.svg";
import { ReactComponent as MainLogoBlue } from "../../Assets/svg/main-logo-blue.svg";
import { Accordion, Dropdown, DropdownButton } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { navbarRoutes } from "./navbarRoutes";
import { AiOutlinePlus as Plus } from "react-icons/ai";
import Button from "../Button/Button";
import { appRoutes } from "../../Utils/routes";

const NavBar = () => {
    const [expanded, setExpanded] = useState(false);

    const ref = useRef(null);
    const closeRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = (e, route) => {
        // e.stopPropagation();
        if (!route.submenu) {
            setExpanded(false);
            navigate(route.route);
        }
    };

    const toggleNavbar = () => {
        setExpanded((prev) => {
            return prev === true ? false : true;
        });
    };

    const getQuote = () => {
        console.log("get a quote");
    };

    const handleClickOutside = useCallback(
        (event) => {
            if (
                ref.current &&
                !ref.current.contains(event.target) &&
                closeRef.current &&
                !closeRef.current.contains(event.target)
            ) {
                if (expanded) {
                    setExpanded(false);
                }
            }
        },
        [ref, closeRef, expanded]
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, true);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside, true);
        };
    }, [ref, expanded, handleClickOutside]);

    return (
        <nav id="main-navbar">
            <div className="container-main d-flex align-items-center justify-content-between position-relative">
                <div className="logo-title-container d-flex align-items-center justify-content-cventer gap-3">
                    {location.pathname === appRoutes.howItWorksCustom ? (
                        <MainLogoBlue className="main-logo-navbar" />
                    ) : (
                        <MainLogo className="main-logo-navbar" />
                    )}
                    <div className="color-main-white font-size-28 font-pnb line-height-1 text-unselectable">
                        Martoms <br />
                        Brothers &#38; Co
                    </div>
                </div>

                <div className="routes-container align-items-center justidy-content-center gap-3 gap-xl-4 d-none d-lg-flex">
                    {navbarRoutes.map((route, index) => {
                        if (!route.submenu)
                            return (
                                <div
                                    key={index}
                                    className={`navbar-route text-unselectable ${
                                        location.pathname === route.route ? "active-route" : ""
                                    }`}
                                    onClick={(e) => handleNavigation(e, route)}>
                                    {route.title}
                                </div>
                            );
                        else
                            return (
                                <React.Fragment key={index}>
                                    <DropdownButton
                                        id={`${
                                            route.route.includes(location.pathname)
                                                ? "dropdown-button-active"
                                                : "dropdown-button"
                                        }`}
                                        title={route.title}
                                        menuVariant="dark">
                                        {route.submenuItems.map((item, index2) => {
                                            return (
                                                <Dropdown.Item
                                                    key={index2}
                                                    onClick={(e) => handleNavigation(e, item)}>
                                                    <span
                                                        className={`${
                                                            location.pathname === item.route
                                                                ? "active-sub-route"
                                                                : ""
                                                        }`}>
                                                        {item.title}
                                                    </span>
                                                </Dropdown.Item>
                                            );
                                        })}
                                    </DropdownButton>
                                </React.Fragment>
                            );
                    })}
                </div>

                <div
                    ref={ref}
                    className={`h-auto routes-container-mobile align-items-start justidy-content-center gap-3 d-flex flex-column d-lg-none ${
                        expanded ? "navbar-expanded" : "navbar-not-expanded"
                    }`}>
                    <Accordion defaultActiveKey={`0`}>
                        {navbarRoutes.map((route, index) => {
                            return (
                                <Accordion.Item eventKey={`${index}`} key={index}>
                                    <Accordion.Header className="font-pnsb">
                                        <div
                                            className={`navbar-route-mobile ${
                                                route.submenu
                                                    ? route.route.includes(location.pathname)
                                                        ? "active-route"
                                                        : ""
                                                    : route.route === location.pathname
                                                    ? "active-route"
                                                    : ""
                                            }`}
                                            onClick={(e) => {
                                                !route.submenu && handleNavigation(e, route);
                                            }}>
                                            {route.title}
                                        </div>{" "}
                                        {route.submenu && <Plus className="button-transition" />}
                                    </Accordion.Header>
                                    {route.submenu ? (
                                        <Accordion.Body>
                                            {route.submenuItems.map((item, index2) => {
                                                return (
                                                    <div
                                                        key={index2}
                                                        className={`navbar-route-mobile ${
                                                            item.route === location.pathname
                                                                ? "active-route"
                                                                : ""
                                                        }`}
                                                        onClick={(e) => handleNavigation(e, item)}>
                                                        {item.title}
                                                    </div>
                                                );
                                            })}
                                        </Accordion.Body>
                                    ) : null}
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                    <div className="d-block d-lg-none">
                        <Button
                            title={"Get a quote"}
                            height={48}
                            heightResponsive={32}
                            color={"#323232"}
                            hoverColor={"#323232"}
                            outlineColor={"transparent"}
                            bgColor={"#02FDB1"}
                            customClass="navbar-btn"
                        />
                    </div>
                </div>

                <div className="d-none d-lg-block">
                    <Button
                        title={"Get a quote"}
                        height={48}
                        heightResponsive={32}
                        color={"#323232"}
                        hoverColor={"#323232"}
                        outlineColor={"transparent"}
                        bgColor={"#02FDB1"}
                        customClass="navbar-btn"
                    />
                </div>

                <div
                    ref={closeRef}
                    className={`button-menu-mobile d-flex d-lg-none ${
                        expanded ? "button-menu-mobile-expanded" : ""
                    }`}
                    onClick={() => toggleNavbar()}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
