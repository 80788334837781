import React, { useRef, useState } from "react";
import { useId } from "react";
import Slider from "react-slick";
import SliderCard from "../../../Components/SliderCard/SliderCard";
import { sliderData } from "./sliderData";
import { ReactComponent as LeftArrow } from "./Assets/chevron_left.svg";
import { ReactComponent as RightArrow } from "./Assets/chevron_right.svg";
import UpperTitle from "../../../Components/UpperTitle/UpperTitle";
import "./WhyChoose.scss";
import SliderButtons from "../../../Components/SliderRight/SliderButtons/SliderButtons";
import SliderRight from "../../../Components/SliderRight/SliderRight";

const WhyChoose = () => {
    const id = useId();
    const sliderRef = useRef();

    const [atStart, setAtStart] = useState(true);
    const [atEnd, setAtEnd] = useState(false);

    return (
        <section id="why-choose" className="py-5">
            <div className="container-main d-flex flex-column flex-sm-row position-relative w-100">
                <div className="d-flex  w-100 align-items-start justify-content-start flex-column gap-2">
                    <UpperTitle
                        className="bg-main-black px-2 rounded-1 d-inline-block color-light-green mb-2"
                        title="START WITH CONFIDENCE"
                    />
                    <h1 className="font-size-64 font-pnb color-main-black line-height-13">
                        Built for all needs.
                    </h1>
                </div>

                <SliderButtons
                    sliderRef={sliderRef}
                    atStart={atStart}
                    atEnd={atEnd}
                    setAtEnd={setAtEnd}
                    setAtStart={setAtStart}
                />
            </div>

            <SliderRight
                sliderRef={sliderRef}
                atStart={atStart}
                atEnd={atEnd}
                setAtEnd={setAtEnd}
                setAtStart={setAtStart}>
                {sliderData.map((el, index) => {
                    return (
                        <div className="slide" key={id + index}>
                            <SliderCard
                                title={el.title}
                                img={el.img}
                                description={el.description}
                                premium={el.premium}
                            />
                        </div>
                    );
                })}
            </SliderRight>
        </section>
    );
};

export default WhyChoose;
