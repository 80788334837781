import React from "react";
import "./Hero.scss";
import HeroUpperTitle from "../../../Components/HeroUpperTitle/HeroUpperTitle";
import { ReactComponent as ShieldIco } from "../../../Assets/svg/shield-check.svg";
import { ReactComponent as PlayBtn } from "../../../Assets/svg/play-btn.svg";
import { Row, Col } from "react-bootstrap";
import Title from "../../../Components/Title/Title";
import Button from "../../../Components/Button/Button";
import ShieldImg from "../../../Assets/png/shield-img.png";
import GradientCountdown from "../../../Components/GradientCountdown/GradientCountdown";
import { MdOutlineCloudDownload } from "react-icons/md";

const Hero = () => {
    const test = () => {
        console.log("test");
    };
    return (
        <section id="homepage-hero">
            <div className="container-main overflow-hidden">
                <Row xs={1} sm={1} md={2} lg={2} className="h-100">
                    <Col className="h-100 order-2 order-md-1">
                        <div className="h-100 d-flex align-items-start justify-content-center flex-column">
                            <HeroUpperTitle
                                title={"AUTOMATICALLY MANAGED MUTUAL FUND"}
                                ico={<ShieldIco />}
                            />
                            <Title title={"We got your back"} punctuation={"."} color={"#FFFFFF"} />
                            <p className="paragraph-container font-size-18 font-pnr line-height-14 color-main-white mb-2 ">
                                MartomsBrothers & Co. is a path-breaking project that applies the
                                principles of blockchain technology to the performance bonds
                                industry, paving the way for future development in crypto bonds.
                            </p>
                            <div className="mb-4 d-flex align-items-start justify-content-start gap-3 gap-lg-4 flex-column flex-xs-row mt-5">
                                <Button
                                    title={"Learn more"}
                                    height={48}
                                    heightResponsive={32}
                                    color={"#000"}
                                    outlineColor={"transparent"}
                                    hoverColor={"#000000"}
                                    bgColor={"#02FDB1"}
                                    bgHoverColor={"#FFFFFF"}
                                    arrow={"right"}
                                    customClass="rounded-3"
                                    fontClass="font-size-16"
                                />
                                <Button
                                    title={"Watch video"}
                                    height={48}
                                    heightResponsive={32}
                                    color={"#FFFFFF"}
                                    outlineColor={"transparent"}
                                    hoverColor={"#e3d6a2"}
                                    bgColor={"transparent"}
                                    image={<PlayBtn />}
                                    align={"start"}
                                    specialPadding={"0px 10px 0px 0px"}
                                />
                            </div>
                            {/* <div className="d-flex align-items-center align-items-sm-start justify-content-center justify-content-sm-start w-100 gap-3 gap-lg-4">
                                <Button
                                    title={"Whitepaper"}
                                    height={48}
                                    heightResponsive={32}
                                    color={"#fff"}
                                    outlineColor={"#fff"}
                                    hoverColor={"#000000"}
                                    bgColor={"transparent"}
                                    bgHoverColor={"#12784a"}
                                    customClass="rounded-3"
                                    fontClass="font-size-16 text-light"
                                    icon={<MdOutlineCloudDownload color="#fff" size={20} />}
                                />
                                <Button
                                    title={"Lightpaper"}
                                    height={48}
                                    heightResponsive={32}
                                    color={"#fff"}
                                    outlineColor={"#fff"}
                                    hoverColor={"#000000"}
                                    bgColor={"transparent"}
                                    bgHoverColor={"#12784a"}
                                    customClass="rounded-3"
                                    fontClass="font-size-16 text-light"
                                    icon={<MdOutlineCloudDownload color="#fff" size={20} />}
                                />
                            </div> */}
                        </div>
                    </Col>

                    <Col className="d-flex  order-1 order-md-2">
                        <img
                            src={ShieldImg}
                            alt="shield"
                            className="img-fluid hero-shield mt-auto mx-auto"
                        />
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Hero;
