import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import Title from "../../Components/Title/Title";
import UpperTitle from "../../Components/UpperTitle/UpperTitle";
import CustomPoolsImg from "../../Assets/png/custom-pools.png";
import "./CustomPools.scss";
import Hero from "../../Components/Hero/Hero";
import heroImg from "./Assets/custom-pools.png";
import PoolList from "../../Components/PoolList/PoolList";
import livepotlogo from "../../Assets/png/pools/livepotlogo.png";

const CustomPools = () => {
    return (
        <section id="pools-custom" className="sections-spacing">
            <Helmet>
                <title>MB&#38;CO | Custom pools</title>
            </Helmet>

            <Hero
                title="Custom pools"
                titleBadge="WE GOT YOU COVERED"
                description="MartomsBrothers & Co. is a path-breaking project that applies the principles of blockchain technology to the performance bonds industry, paving the way for future development in crypto bonds."
                image={heroImg}
            />

            <div className="sections-spacing">
                <div className="container-main w-100">
                    <div className="d-inline-block">
                        <UpperTitle
                            title={"LAST"}
                            className="bg-main-black color-light-green px-2 rounded-1 mb-2"
                        />
                    </div>

                    <Title
                        title={"Custom pools"}
                        punctuation={"."}
                        color={"#323232"}
                        spacing={"big"}
                    />

                    <PoolList
                        logo={livepotlogo}
                        logoMaxWidth={247}
                        theme="purple"
                        risk="low"
                        riskTitle="CUSTOM POOL"
                        bigNumber="1.521556"
                        btnLink="/custom-pools/livepot"
                    />
                </div>
            </div>
        </section>
    );
};

export default CustomPools;
