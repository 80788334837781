import livepotHero from "./Assets/livepot_hero.png";
import livepotLogo from "../../Assets/png/pools/livepotlogo.png"
import { ReactComponent as MartomsSmallLogo } from "../../Assets/svg/martoms-small-green-logo.svg"
import { ReactComponent as LivepotSmallLogo } from "../../Assets/svg/livepot-small-logo.svg"
import { BsLink45Deg } from "react-icons/bs";
import { SlSocialTwitter } from "react-icons/sl";
import { LuFacebook } from "react-icons/lu";
import { FiYoutube } from "react-icons/fi";
import { RxDiscordLogo } from "react-icons/rx"
import { FaTiktok } from "react-icons/fa"
import presentation from "./Assets/LivePot_Short_Presentation.pdf"

export const livepotData = {
    poolInformation: {
        name: "LivePot.com",
        heroImage: livepotHero,
        description: "LivePot is a Gaming platform based on the revolutionary Crypto Betting concept and developed on cutting-edge Web3 technology. LivePot is a platform that enables users to play and bet with various types of cryptocurrencies. They aim is to provide a fair and equitable chance for all users to win exciting rewards and prizes. LivePot provides all the required infrastructure features for users to play&bet or to operate their own online casino-based games without the need for licences.",
        social: [
            {
                label: "Website",
                icon: <BsLink45Deg color="#12784A" size={25} />,
                urlLabel: "livepot.com",
                url: "https://livepot.com"
            },
            {
                label: "Twitter",
                icon: <SlSocialTwitter color="#12784A" size={25} />,
                urlLabel: "LivePotOfficial",
                url: "https://twitter.com/LivePotOfficial"
            },
            {
                label: "Facebook",
                icon: <LuFacebook color="#12784A" size={25} />,
                urlLabel: "LivePot",
                url: "https://www.facebook.com/profile.php?id=100091509611728&is_tour_dismissed=true"
            },
            {
                label: "YouTube",
                icon: <FiYoutube color="#12784A" size={25} />,
                urlLabel: "LivePot",
                url: "https://www.youtube.com/channel/UC2XWJzPqJ77rqdWADC0WidQ"
            },
            {
                label: "Discord",
                icon: <RxDiscordLogo color="#12784A" size={25} />,
                urlLabel: "LivePot",
                url: "https://www.youtube.com/channel/UC2XWJzPqJ77rqdWADC0WidQ"
            },
            {
                label: "TikTok",
                icon: <FaTiktok color="#12784A" size={25} />,
                urlLabel: "LivePotOfficial",
                url: "https://www.tiktok.com/@livepotofficial"
            }
        ],
        documents: [
            {
                label: "Presentation",
                pdf: presentation
            },
            {
                label: "Whitepaper",
                pdf: "https://livepot.s3.eu-central-1.amazonaws.com/Livepot/Whitepaper/LivePotWhitepaper.pdf"
            },
        ]
    },
    totalSold: {
        LIPO: "1.000.000",
        USD: "1.440.123 EUR"
    },
    active: true,
    riskCoefficient: 1.5,
    logo: livepotLogo,
    chainID: "0x3cf434e84fbe6d7676e29b0fb7e97eea65ec1711f943c45e0cb372727edd96ae3rds",
    beneficiary: {
        logo: <MartomsSmallLogo />,
        name: "Martoms Brothers & Co.",
    },
    issuedBy: {
        logo: <LivepotSmallLogo />,
        name: "LivePot",
    },
    guarantee: {
        bank1: {
            logo: "",
            name: "ASIA PACIFIC INVESTMENT BANK",
            issueDate: "01 JUNE 2023",
            startDate: "12 JUNE 2023",
            expireDate: "12 JUNE 2024",
            documentImage: "",
            guaranteeDocument: "",
            contractDocument: "",
        }
    }
}