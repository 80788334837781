import React, { useRef } from "react";
import { appRoutes } from "./routes";

let howItWorksCustomRefSection1 = {};
let howItWorksStandardRefSection1 = {};
let homepageFirstSectionRef = {};

const handleScrollIntoView = async (scrollRef, route, currentRoute, navigate, behavoir) => {
    if (scrollRef.current === null) {
        return;
    }
    if (currentRoute !== route) {
        await navigate(route);
    }

    const element = scrollRef.current;
    const headerOffset = 60;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: behavoir ? "auto" : "smooth"
    });
};

const Refs = () => {
    howItWorksCustomRefSection1 = { ref: useRef(null), route: appRoutes.howItWorksCustom };
    homepageFirstSectionRef = { ref: useRef(null), route: appRoutes.home };
    howItWorksStandardRefSection1 = { ref: useRef(null), route: appRoutes.howItWorksStandard };

    return null;
};

export {
    howItWorksCustomRefSection1,
    homepageFirstSectionRef,
    howItWorksStandardRefSection1,
    handleScrollIntoView,
    Refs
};
