import React from 'react'
import "./Hero.scss";
import HeroUpperTitle from '../../../../Components/HeroUpperTitle/HeroUpperTitle';
import { ReactComponent as ShieldIco } from "../../../../Assets/svg/shield-check.svg";
import { Row, Col } from "react-bootstrap"
import Title from '../../../../Components/Title/Title';
import Button from '../../../../Components/Button/Button';
import CustomImg from "../../Assets/custom.png";
import { handleScrollIntoView, howItWorksCustomRefSection1 } from '../../../../Utils/Refs';

import { useLocation, useNavigate } from 'react-router-dom';

const Hero = () => {

    const navigation = useNavigate()
    const location = useLocation();

    return (
        <section id="how-it-works-custom-hero">
            <div className='container-main overflow-hidden'>

                <Row xs={1} sm={1} md={2} lg={2} className="h-100">
                    <Col className="h-100 order-2 order-md-1">
                        <div className='h-100 d-flex align-items-start justify-content-center flex-column'>
                            <HeroUpperTitle title={"CUSTOM POOLS"} ico={<ShieldIco />} custom={true} />
                            <Title title={"How it works"} punctuation={"."} color={"#FFFFFF"} />
                            <p className='paragraph-container font-size-18 font-pnr line-height-12 color-main-white mb-2 '>
                                A wonderful serenity has taken possession of my entire soul,
                                like these sweet mornings of spring which I enjoy with my whole heart.
                                I am alone, and feel the charm of existence in this spot, which was created
                                for the bliss of souls like mine.
                            </p>
                            <div className='mb-4 d-flex align-items-start justify-content-start gap-3 gap-lg-4 flex-column flex-xs-row mt-5'>
                                <Button
                                    title={"Jump to content"}
                                    height={48}
                                    heightResponsive={42}
                                    color={"#FFFFFF"}
                                    outlineColor={"#FFFFFF"}
                                    hoverColor={"#000000"}
                                    bgColor={"transparent"}
                                    bgHoverColor={"#FFFFFF"}
                                    arrow={"right"}
                                    callback={() => handleScrollIntoView(howItWorksCustomRefSection1.ref, howItWorksCustomRefSection1.route, location.pathname, navigation, true)}
                                />
                            </div>
                        </div>
                    </Col>

                    <Col className="d-flex  order-1 order-md-2 mb-3 mb-md-0">
                        <img src={CustomImg} alt="shield" className="img-fluid hero-shield m-auto" />
                    </Col>
                </Row>
            </div>

        </section>
    )
}

export default Hero