import React from "react";

const UpperTitle = ({ title, color, className }) => {
    return (
        <div
            className={`uppertitle ${className ? className : null} font-size-16 font-pnm ${
                !color ? "color-main-green" : "color-light-green"
            }`}>
            {title?.toUpperCase()}
        </div>
    );
};

export default UpperTitle;
