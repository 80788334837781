import { Route, Routes, useLocation } from "react-router-dom";
import "./Styles/main.scss";
import "react-toastify/dist/ReactToastify.css";
import Homepage from "./Views/Homepage/Homepage";
import { appRoutes } from "./Utils/routes";
import NotFound from "./Views/NotFound/NotFound";
import NavBar from "./Components/NavBar/NavBar";
import { ToastContainer } from "react-toastify";
import Footer from "./Components/Footer/Footer";
import Pools from "./Views/Pools/Pools";
import Exchange from "./Views/Exchange/Exchange";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import HowItWorksCustom from "./Views/HowItWorks/HowItWorksCustom/HowItWorksCustom";
import HowItWorksStandard from "./Views/HowItWorks/HowItWorksStandard/HowItWorksStandard";
import { Refs } from "./Utils/Refs";

import "./App.scss";
import CustomPools from "./Views/CustomPools/CustomPools";
import SinglePool from "./Views/SinglePool/SinglePool";

//TODO: Don't forget the meta tags!

function App() {
    const location = useLocation();

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                closeButton={true}
                hideProgressBar={true}
            />
            <Refs />

            <div
                className="App"
                blue-theme={location.pathname === appRoutes.howItWorksCustom ? "true" : "false"}>
                <ScrollToTop />

                <NavBar />

                <Routes>
                    <Route path={appRoutes.home} element={<Homepage />} />
                    <Route path={appRoutes.notFound} element={<NotFound />} />
                    <Route path={appRoutes.pools} element={<Pools />} />
                    <Route path={appRoutes.exchange} element={<Exchange />} />
                    <Route path={appRoutes.howItWorksCustom} element={<HowItWorksCustom />} />
                    <Route path={appRoutes.howItWorksStandard} element={<HowItWorksStandard />} />

                    <Route path={appRoutes.customPools} element={<CustomPools />} />
                    <Route path={appRoutes.customPoolsId} element={<SinglePool />} />
                </Routes>

                <Footer />
            </div>
        </>
    );
}

export default App;
