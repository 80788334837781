import React from 'react';
import Step1 from "./Assets/step1.png"
import Step2 from "./Assets/step2.png"
import Step3 from "./Assets/step3.png"

import "./StepComponent.scss";




const StepComponent = ({
    number,
    title,
    content
}) => {

    const handleStep = (step) => {
        switch (step) {
            case "1":
                return <img src={Step1} alt="Steps" className='img-fluid' />
            case "2":
                return <img src={Step2} alt="Steps" className='img-fluid' />
            case "3":
                return <img src={Step3} alt="Steps" className='img-fluid' />
            default:
                return <img src={Step1} alt="Steps" className='img-fluid' />
        }
    }

    return (
        <div className='step-component-homepage w-100 d-flex align-items-center justify-content-start justify-content-sm-center flex-row flex-sm-column'>
            <div className={`step-number font-size-28 font-pnb line-height-1 mb-auto mb-sm-3 me-3 me-sm-0`}>{handleStep(number)}</div>
            <div className='d-flex align-items-center justify-content-start justify-content-sm-center flex-column'>
                <div className='font-pnb font-size-24 color-main-white mb-1 mb-sm-3 text-start text-sm-center w-100'>{title}</div>
                <div className={`${number === "3" && "mb-0"} ${number === "3" && "remove-dash"} content-step font-size-16 font-pnr color-main-white text-start text-sm-center line-height-15`}>{content}</div>
            </div>
        </div>
    )
}

export default StepComponent