import React, { useState, useEffect } from 'react'
import { ReactComponent as LeftArrow } from "../../../Views/Homepage/WhyChoose/Assets/chevron_left.svg"
import { ReactComponent as RightArrow } from "../../../Views/Homepage/WhyChoose/Assets/chevron_right.svg"
import Button from '../../Button/Button';
import { detectEnd, detectStart } from '../SliderUtils';
import "./SliderButtons.scss"

const SliderButtons = ({ sliderRef, atEnd, setAtEnd, atStart, setAtStart }) => {
    const [rerender, setRerender] = useState(false)

    useEffect(() => {
        const timeOut = setTimeout(() => {
            detectStart(atStart, setAtStart, setAtEnd, sliderRef)
            detectEnd(atEnd, setAtEnd, setAtStart, sliderRef)
        }, 400)

        return () => {
            clearTimeout(timeOut)
        }
    }, [rerender])

    return (
        <div className={`slider-right-buttons d-flex align-items-end justify-content-end gap-3`}>
            <Button
                title={""}
                height={64}
                heightResponsive={54}
                heightResponsive580={42}
                minWidth={64}
                minWidth991={54}
                minWidth580={42}
                outlineColor={"#f5f5f5"}
                color={atStart ? "#b7b7b7" : "#FFFFFF"}
                bgColor={atStart ? "#FFFFFF" : "#12784a"}
                hoverColor={atStart ? "#b7b7b7" : "#FFFFFF"}
                callback={(e) => {
                    e.preventDefault();
                    let scrollLeft = sliderRef.current.scrollLeft
                    sliderRef.current.scroll({ left: scrollLeft - 250, behavior: "smooth" })
                    setRerender(!rerender)
                }}
                bgHoverColor={atStart ? "#FFFFFF" : "#12784a"}
                image={<LeftArrow />}
                disableBtn={atStart}
            />

            <Button
                title={""}
                height={64}
                heightResponsive={54}
                heightResponsive580={42}
                minWidth={64}
                minWidth991={54}
                minWidth580={42}
                outlineColor={"#f5f5f5"}
                color={atEnd ? "#b7b7b7" : "#FFFFFF"}
                bgColor={atEnd ? "#FFFFFF" : "#12784a"}
                hoverColor={atEnd ? "#b7b7b7" : "#FFFFFF"}
                callback={(e) => {
                    e.preventDefault();
                    let scrollLeft = sliderRef.current.scrollLeft
                    sliderRef.current.scroll({ left: scrollLeft + 250, behavior: "smooth" })
                    setRerender(!rerender)
                }}
                bgHoverColor={atEnd ? "#FFFFFF" : "#12784a"}
                image={<RightArrow />}
                disableBtn={atEnd}
            />

        </div>
    )
}

export default SliderButtons