import { ReactComponent as LowRiskIco } from "../Assets/svg/low-risk.svg";
import { ReactComponent as MedRiskIco } from "../Assets/svg/med-risk.svg";
import { ReactComponent as HiRiskIco } from "../Assets/svg/high-risk.svg";
import { ReactComponent as ShieldIco } from "../Assets/svg/shield-plus.svg";
import { ReactComponent as PlusIco } from "../Assets/svg/plus-circle.svg";

export const RISKS = {
    low: <LowRiskIco />,
    medium: <MedRiskIco />,
    high: <HiRiskIco />,
    shield: <ShieldIco />
};

export const RiskIcon = ({ poolRisk, title, lightColor, color, className }) => {
    return (
        <div
            className={`risk-title-container font-size-16 font-pnm py-1 text-unselectable ${
                className ? className : null
            }`}
            style={{
                color: `${lightColor}`,
                backgroundColor: `${color}`
            }}>
            {Object.keys(RISKS).includes(poolRisk) ? RISKS[poolRisk] : null}
            {title?.toUpperCase()}
        </div>
    );
};
