import React from "react";
import "./GotYouCovered.scss";
import RisksImg from "./Assets/png/risks.png";
import LosesImg from "./Assets/png/losses.png";
import DangersImg from "./Assets/png/dangers.png";
import Card from "./Card/Card";
import UpperTitle from "../../../Components/UpperTitle/UpperTitle";
import Title from "../../../Components/Title/Title";
import Fade from "react-reveal/Fade";

const cardsData = [
    {
        image: RisksImg,
        orientation: "right",
        uppertitle: "we cover",
        title: "Risks",
        text: "Eliminating or mitigating crypto risks refers to the process of reducing or removing the potential hazards and uncertainties associated with cryptocurrencies, such as market volatility, security threats, and regulatory changes."
    },
    {
        image: DangersImg,
        orientation: "left",
        uppertitle: "we cover",
        title: "Dangers",
        text: "Stay safe while you engage in cryptocurrency activities by protecting yourself from potential risks that may arise. You can ensure a secure and successful experience with cryptocurrency."
    },
    {
        image: LosesImg,
        orientation: "right",
        uppertitle: "we cover",
        title: "Losses",
        text: "Protect your investments in crypto and blockchain projects by taking steps to eliminate potential losses. You can minimize the risks associated with these investments and increase your chances of success."
    }
];

const GotYouCovered = () => {
    return (
        <section id="homepage-covered" className="sections-spacing">
            <div className="mb-0 mb-lg-3 container-main ">
                <UpperTitle
                    className="bg-main-black d-inline-block color-light-green px-3 py-1 rounded-1 mb-2"
                    title={"FOR ALL NEEDS"}
                />
                <Title
                    title={["We got you", <span className="color-main-green"> covered.</span>]}
                    color={"#323232"}
                    spacing={"big"}
                />
                <div className="d-flex align-items-start justify-content-start gap-3 gap-lg-5 flex-column">
                    {cardsData.map((el, index) => (
                        <Fade bottom key={index}>
                            <Card
                                image={el.image}
                                orientation={el.orientation}
                                uppertitle={el.uppertitle}
                                title={el.title}
                                text={el.text}
                            />
                        </Fade>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default GotYouCovered;
