import { Col, Row } from "react-bootstrap";
import HeroUpperTitle from "../HeroUpperTitle/HeroUpperTitle";
import Title from "../Title/Title";
import Button from "../Button/Button";
import { ReactComponent as PlusIco } from "../../Assets/svg/plus-ico.svg";
import { ReactComponent as ShieldIco } from "../../Assets/svg/shield-check.svg";
import { useNavigate } from "react-router-dom";
import "./Hero.scss";

const Hero = ({ titleBadge, image, title, description, buttonLabel, buttonLink }) => {
    const navigate = useNavigate();

    return (
        <section id="pools-hero">
            <div className="container-main overflow-hidden">
                <Row xs={1} sm={1} md={2} lg={2} className="h-100">
                    <Col className="h-100 order-2 order-md-1">
                        <div className="h-100 d-flex align-items-start justify-content-center flex-column">
                            <HeroUpperTitle title={titleBadge} ico={<ShieldIco />} />
                            <Title title={title} punctuation={"."} color={"#FFFFFF"} />
                            <p className="paragraph-container font-size-18 font-pnr line-height-12 color-main-white mb-2 ">
                                {description}
                            </p>
                            {buttonLabel ? (
                                <div className="mb-4 d-flex align-items-start justify-content-start gap-3 gap-lg-4 flex-column flex-xs-row mt-5">
                                    <Button
                                        title={buttonLabel}
                                        height={48}
                                        heightResponsive={32}
                                        color={"#FFFFFF"}
                                        outlineColor={"transparent"}
                                        hoverColor={"#e3d6a2"}
                                        bgColor={"transparent"}
                                        image={<PlusIco />}
                                        align={"start"}
                                        specialPadding={"0px 10px 0px 0px"}
                                        callback={() => navigate(buttonLink)}
                                    />
                                </div>
                            ) : null}
                        </div>
                    </Col>

                    <Col className="d-flex order-1 order-md-2 mb-3 mb-md-0">
                        <img src={image} alt="shield" className="img-fluid m-auto" />
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Hero;
