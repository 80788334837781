import React from "react";
import { assetsThemeColor } from "./assetsObj";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as LowRiskIco } from "../../Assets/svg/low-risk.svg";
import { ReactComponent as MedRiskIco } from "../../Assets/svg/med-risk.svg";
import { ReactComponent as HiRiskIco } from "../../Assets/svg/high-risk.svg";
import { ReactComponent as ShieldIco } from "../../Assets/svg/shield-plus.svg";

import "./PoolList.scss";
import Button from "../Button/Button";
import PoolDataRow from "./PoolDataRow";
import { useNavigate } from "react-router-dom";

const themes = ["red", "green", "orange", "blue", "purple"];
const RISKS = {
    low: <LowRiskIco />,
    med: <MedRiskIco />,
    hi: <HiRiskIco />,
    shield: <ShieldIco />
};
const COLORS = {
    green: "#3BBF83",
    orange: "#F39B30",
    red: "#EE4A59",
    blue: "#1D98B5",
    purple: "#9B40EF"
};

const PoolList = ({ theme, risk, riskTitle, btnLink, logo, logoMaxWidth, bigNumber }) => {
    const navigate = useNavigate();

    const getRiskIco = (risk) => {
        if (Object.keys(RISKS).includes(risk)) return RISKS[risk];
        return "";
    };

    const getColor = (risk) => {
        if (Object.keys(COLORS).includes(risk)) return COLORS[risk];
        return "#000000";
    };

    if (!themes.includes(theme)) {
        console.log("invalid theme");
        return null;
    }

    return (
        <div id="pool-list" className="gray-border p-4 p-lg-5 mt-4">
            <Row>
                <Col sm={12} md={12} lg={3}>
                    <div className="d-flex align-items-start justify-content-between gap-2 gap-md-4 flex-column flex-sm-row flex-lg-column">
                        <div className="d-flex align-items-start justify-content-start flex-column gap-3 mb-0 mb-sm-4 mb-lg-0">
                            <div
                                className={`risk-title-container font-size-16 text-nowrap font-pnm py-1 text-unselectable`}
                                style={{
                                    color: `${getColor(theme)}`,
                                    backgroundColor: `transparent`,
                                    border: `1px solid ${getColor(theme)}`
                                }}>
                                {getRiskIco(risk)}
                                {riskTitle?.toUpperCase()}
                            </div>

                            <div className="d-flex my-3">
                                <img src={logo} width={logoMaxWidth} alt="" />
                            </div>

                            <div className="d-flex align-items-start justify-content-start gap-1 flex-column">
                                <span
                                    className="font-size-48 font-pnb line-height-1"
                                    style={{ color: getColor(theme) }}>
                                    {bigNumber}
                                </span>
                                <div className="d-flex align-items-center justify-content-start gap-2">
                                    <span className="font-size-16 font-pnm line-height-1 color-main-gray">
                                        Risk coefficient
                                    </span>
                                    {assetsThemeColor[theme]?.info}
                                </div>
                            </div>
                        </div>

                        <Button
                            title="Learn more"
                            height={56}
                            heightResponsive={48}
                            color={getColor(theme)}
                            outlineColor={getColor(theme)}
                            bgColor="transparent"
                            hoverColor="#FFFFFF"
                            bgHoverColor={getColor(theme)}
                            arrow="right"
                            minWidth={200}
                            minWidth991={150}
                            minWidth580={130}
                            callback={() => navigate(btnLink)}
                        />
                    </div>
                </Col>

                <Col lg={9}>
                    <Row className="h-100">
                        <Col lg={12} className="my-sm-0 my-4">
                            <span className="color-pill-green bg-pill-green font-size-16 px-3 py-2 radius-4">
                                Accepted
                            </span>
                        </Col>
                        <Col sm={12} md={12} lg={4} className="d-flex info-data-column">
                            <div className="d-flex flex-row flex-lg-column align-items-start justify-content-between info-data-column w-100 h-100">
                                <PoolDataRow
                                    title="Total assets"
                                    value="1.4 mil EUR"
                                    ico={assetsThemeColor[theme]?.totalAssets}
                                />
                                <PoolDataRow
                                    title="Total bonds"
                                    value="6"
                                    ico={assetsThemeColor[theme]?.totalBonds}
                                />
                                <PoolDataRow
                                    title="Funds available"
                                    value="1.4 mil EUR"
                                    ico={assetsThemeColor[theme]?.fundsAvailable}
                                />

                                <PoolDataRow
                                    title="Backup funds"
                                    value="1.4 mil EUR"
                                    className="d-flex d-sm-none"
                                    ico={assetsThemeColor[theme]?.backupFunds}
                                />
                            </div>
                        </Col>

                        <Col sm={12} md={12} lg={4} className="d-flex info-data-column">
                            <div className="d-flex flex-row flex-lg-column align-items-start justify-content-between info-data-column w-100 h-100">
                                <PoolDataRow
                                    title="Backup funds"
                                    value="1.4 mil EUR"
                                    className="d-none d-sm-flex"
                                    ico={assetsThemeColor[theme]?.backupFunds}
                                />

                                <PoolDataRow
                                    title="Active bonds"
                                    value="1"
                                    ico={assetsThemeColor[theme]?.activeBonds}
                                />
                                <PoolDataRow
                                    title="Funds locked"
                                    value="1.4 mil EUR"
                                    ico={assetsThemeColor[theme]?.fundsLocked}
                                />
                            </div>
                        </Col>

                        <Col sm={12} md={12} lg={4} className="d-flex info-data-column">
                            <div className="d-flex flex-sm-row flex-lg-column align-items-start justify-content-between info-data-column w-100 h-100">
                                <PoolDataRow
                                    title="Total commisions"
                                    value="0"
                                    ico={assetsThemeColor[theme]?.totalCommisions}
                                />
                                <PoolDataRow
                                    title="Claimed bonds"
                                    value="0"
                                    ico={assetsThemeColor[theme]?.claimedBonds}
                                />
                                <PoolDataRow
                                    title="Enrolled wallets"
                                    value="1515"
                                    ico={assetsThemeColor[theme]?.enrolledWallets}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default PoolList;
