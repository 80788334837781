import React from "react";
import { Helmet } from "react-helmet";
import StandardPools from "./StandardPools/StandardPools";
import WhyChoose from "../Homepage/WhyChoose/WhyChoose";
import Roles from "./Roles/Roles";
import RolesCustom from "./RolesCustom/RolesCustom";
import StandardList from "./StandardList/StandardList";
import CustomList from "./CustomList/CustomList";
import Table from "./Table/Table";
import Hero from "../../Components/Hero/Hero";

const Pools = () => {
    return (
        <section id="pools">
            <Helmet>
                <title>MB&#38;CO | Pools</title>
            </Helmet>

            <Hero
                title="Risk pools"
                titleBadge="WE GOT YOU COVERED"
                description="A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine."
                buttonLabel="Get started"
                buttonLink="/"
            />
            <StandardPools />
            <Roles />
            <StandardList />
            <Table />
            <RolesCustom />
            <CustomList />
            <WhyChoose />
        </section>
    );
};

export default Pools;
