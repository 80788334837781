import React from "react";
import { useState } from "react";
import Button from "../Button/Button";
import MBLogo from "../../Assets/png/mt-logo.png";
import MBLogoBlue from "../../Assets/png/mt-logo-blue.png";
import { ReactComponent as CryptoDATALogo } from "../../Assets/svg/cryptodata.svg";
import { ReactComponent as FacebookIco } from "../../Assets/svg/023-facebook.svg";
import { ReactComponent as InstagramIco } from "../../Assets/svg/044-instagram.svg";
import { ReactComponent as TwitterIco } from "../../Assets/svg/096-twitter.svg";
import { ReactComponent as TelegramIco } from "../../Assets/svg/089-telegram.svg";
import { ReactComponent as MediumIco } from "../../Assets/svg/054-medium.svg";
import { ReactComponent as YoutubeIco } from "../../Assets/svg/116-youtube.svg";
import { Row, Col, Accordion } from "react-bootstrap";
import axios from "axios";
import "./Footer.scss";
import { toast } from "react-toastify";
import { appRoutes } from "../../Utils/routes";
import { Link, useLocation } from "react-router-dom";
import { HiOutlinePlus as PlusIco } from "react-icons/hi";
import { footerLinks } from "./constants";

const Footer = () => {
    const [email, setEmail] = useState(() => "");
    const [disableBtn, setDisableBtn] = useState(() => false);
    const location = useLocation();

    const validateEmail = (email) => {
        let emailError;

        if (!email) {
            emailError = "Email field can't be empty";
            toast.error(emailError, { toastId: "email_erroe" });
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            emailError = "Invalid email!";
            toast.error(emailError, { toastId: "email_erroe" });
        } else {
            submitEmail(email);
        }
    };

    const submitEmail = async (email) => {
        try {
            const response = await axios.post(
                "https://jupiter-test.com/api/invitation/subscribe-cryptodata",
                {
                    email: email
                }
            );
            if (response.status === 200) {
                toast.success("Registered successfully!", { toastId: "success-registration" });
                setDisableBtn(true);
                setEmail("");
            }
        } catch (err) {
            console.log(err);
            toast.error("An error occured. Please try again later!", { toastId: "register-err" });
        }
    };

    return (
        <footer id="footer">
            <div className="subscribe-section py-4">
                <div className="container-main d-flex flex-column flex-md-row align-items-center justify-content-between gap-3">
                    <div className="d-flex align-items-md-start align-items-center justify-content-md-start justify-content-center gap-1 flex-column">
                        <span className="font-size-40 text-center text-md-start font-pnb color-white line-height-1">
                            Subscribe to newsletter
                        </span>
                        <span className="font-size-18 text-center text-md-start font-pnr color-white line-height-1">
                            Only important news and promotions. Never spam.
                        </span>
                    </div>
                    <div className="input-container w-50 d-flex align-items-center justify-content-center">
                        <input
                            type="text"
                            value={email}
                            placeholder="Enter your email address"
                            onChange={(e) => setEmail(e.target.value)}
                            id="email-input"
                            className="font-size-20 font-pnm p-3 radius-8"
                        />
                        <Button
                            title="Subscribe"
                            height="64"
                            heightResponsive="42"
                            heightResponsive580={42}
                            color="#0B482C"
                            outlineColor="transparent"
                            bgColor="#02FDB1"
                            disableBtn={disableBtn}
                            callback={() => validateEmail(email)}
                            customClass="footer-subscribe-btn radius-8"
                        />
                    </div>
                </div>
            </div>
            <div className="footer-section py-5">
                <div className="container-main">
                    <Row>
                        <Col md={12} lg={4}>
                            <div className="content-container">
                                <div className="d-flex align-items-center justify-content-start gap-3 pb-3">
                                    {location.pathname === appRoutes.howItWorksCustom ? (
                                        <img src={MBLogoBlue} alt="MB" className="img-fluid" />
                                    ) : (
                                        <img src={MBLogo} alt="MB" className="img-fluid" />
                                    )}

                                    <div className="d-flex flex-column">
                                        <span className="font-size-28 font-pnb color-white line-height-13">
                                            Martoms <br />
                                            Brothers & Co.
                                        </span>
                                        <div className="d-flex align-items-center justify-content-start pt-2 flex-wrap">
                                            <span className="font-size-10 font-pnr color-main-gray me-2">
                                                Developed by
                                            </span>
                                            <CryptoDATALogo />
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center mt-4 justify-content-start gap-5 flex-wrap">
                                    <a
                                        rel="noreferrer"
                                        href="https://www.facebook.com/cryptodataofficial"
                                        target="_blank">
                                        <FacebookIco />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href="https://www.instagram.com/cryptodataofficial/"
                                        target="_blank">
                                        <InstagramIco />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href="https://twitter.com/CryptoDATA_Tech"
                                        target="_blank">
                                        <TwitterIco />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href="https://t.me/joinchat/SbIUe1WNLavdynhT"
                                        target="_blank">
                                        <TelegramIco />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href="https://medium.com/@cryptodata"
                                        target="_blank">
                                        <MediumIco />
                                    </a>
                                    <a
                                        rel="noreferrer"
                                        href="https://www.youtube.com/c/CryptoDATAOfficial"
                                        target="_blank">
                                        <YoutubeIco />
                                    </a>
                                </div>
                            </div>
                        </Col>

                        {/* <Col md={12} lg={8}>
                            <div className="h-100 footer-menu align-items-center justify-content-around d-none d-lg-flex">
                                {footerLinks.map((link) => (
                                    <div className="d-flex align-items-start justify-content-start flex-column gap-2">
                                        <span className="font-size-24 font-pnb color-light-green">
                                            {link.section}
                                        </span>
                                        <Link to="/">Protocol cover</Link>
                                        <Link to="/">Custodian cover</Link>
                                        <Link to="/">Exchange cover</Link>
                                        <Link to="/">Project cover</Link>
                                    </div>
                                ))}
                            </div>
                            <div className=" w-100 align-items-center justify-content-around d-flex d-lg-none">
                                <Accordion className="w-100 mt-4" defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <span className="font-size-24 font-pnb color-white pointer">
                                                    Products
                                                </span>
                                                <PlusIco />
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="d-flex align-items-start justify-content-start flex-column gap-2">
                                                <Link to="/">Protocol cover</Link>
                                                <Link to="/">Custodian cover</Link>
                                                <Link to="/">Exchange cover</Link>
                                                <Link to="/">Project cover</Link>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <span className="font-size-24 font-pnb color-white pointer">
                                                    Company
                                                </span>
                                                <PlusIco />
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="d-flex align-items-start justify-content-start flex-column gap-2">
                                                <Link to="/">About</Link>
                                                <Link to="/">Press</Link>
                                                <Link to="/">Careers</Link>
                                                <Link to="/">News</Link>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <span className="font-size-24 font-pnb color-white pointer">
                                                    Legal
                                                </span>
                                                <PlusIco />
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className="d-flex align-items-start justify-content-start flex-column gap-2">
                                                <Link to="/">Terms of Use</Link>
                                                <Link to="/">Privacy Policy</Link>
                                                <Link to="/">Cookie Policy</Link>
                                                <Link to="/">Statement</Link>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col> */}
                    </Row>

                    {/* <div className='separator-line w-100 my-5'></div> */}

                    {/* <Row className="mt-5 pt-5 text-light">
                        <Col>
                            <p className="text-light font-size-14">
                                Martom Brothers & Co Ltd (t/a Martom Brothers & Co) is an appointed
                                representative of Martom Brothers & Co for Insurance Distribution
                                activities. Martom Brothers & Co is authorised and regulated by the
                                Financial Conduct Authority (No. 790558). You can check this by
                                visiting the Financial Services Register at www.fca.org.uk/register.
                            </p>
                            <p className="font-size-14">
                                Martom Brothers & Co Ltd (trading as Martom Brothers & Co) is
                                incorporated in England and Wales with registered number 11356137
                                whose registered office is at Capital Building, Tyndall Street,
                                London, Wales, CF10 4AZ
                            </p>
                        </Col>
                    </Row> */}

                    {/* <span className='font-size-14 font-pnr color-white line-height-1'>
                        
                    </span> */}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
