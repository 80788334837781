import React from 'react';
import "./BenefitCard.scss";
import { ReactComponent as WandImg } from "../Assets/svg/001-magic-wand.svg"
import { ReactComponent as AligmImg } from "../Assets/svg/002-align.svg"
import { ReactComponent as TransformImg } from "../Assets/svg/003-transform.svg"
import { ReactComponent as PalleteImg } from "../Assets/svg/004-palette.svg"
import { ReactComponent as MathImg } from "../Assets/svg/005-math.svg"
import { ReactComponent as CubeImg } from "../Assets/svg/006-cube.svg"

const IMAGES = {
    wand: <WandImg />,
    align: <AligmImg />,
    transform: <TransformImg />,
    pallete: <PalleteImg />,
    math: <MathImg />,
    cube: <CubeImg />
}

const BenefitCard = ({
    benefit,
    title,
    content
}) => {

    const getBenefitImage = (benefit) => {
        if (!Object.keys(IMAGES).includes(benefit)) return

        return IMAGES[benefit]
    }

    return (
        <div className='homepage-benefit-card d-flex align-items-start justify-content-start flex-column'>

            <div className="benefit-ico-container">
                {getBenefitImage(benefit)}
            </div>
            <div className='font-size-24 font-pnb color-main-black mt-2 benefit-title'>
                {title}
            </div>
            <div className='font-size-16 font-pnr color-main-gray mt-2 line-height-14'>
                {content}
            </div>

        </div>
    )
}

export default BenefitCard