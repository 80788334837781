import React from "react";
import CoverageCard from "../../../Components/CoverageCard/CoverageCard";
import Title from "../../../Components/Title/Title";
import UpperTitle from "../../../Components/UpperTitle/UpperTitle";
import AnalyticsImg from "./Assets/analytics.png";
import DataImg from "./Assets/data.png";
import InnovationImg from "./Assets/innovation.png";

const cardsData = [
    {
        img: AnalyticsImg,
        title: ["Exchange", <br />, " Wallet Hacking Protection"],
        props: ["Exchange assets theft"],
        description:
            "Your cryptocurrency investments are always protected. A platform designed to offer the protection you need for your digital assets against wallet hacking attempts, providing a reliable and trusted solution for keeping your cryptocurrencies safe.",
        pool: {
            cost: 0,
            costType: "Coming soon",
            risk: "low",
            lightColor: "#02FDB1",
            color: "#12784A"
        },
        colors: {
            mainColor: "#02FDB1",
            textColor: "#3BBF83"
        }
    },
    {
        img: InnovationImg,
        title: ["Exchange", <br />, "Bad Management Protection"],
        props: ["Rug pull", "Bankruptcy", "Bad Management", "Hacking"],
        description:
            "A secure platform that mitigates potential threats, including bad management, rug pulls, bankruptcy, and hacking, ensuring the utmost safety and protection for your funds. With meticulous risk management protocols, we offer you the peace of mind to confidently navigate the cryptocurrency landscape.",
        pool: {
            cost: 0,
            costType: "Coming soon",
            risk: "low",
            lightColor: "#02FDB1",
            color: "#12784A"
        },
        colors: {
            mainColor: "#02FDB1",
            textColor: "#3BBF83"
        }
    },
    {
        img: DataImg,
        title: ["Exchange", <br />, "Bankrupt Protection"],
        props: ["Rug pull", "Bankruptcy", "Bad Management", "Hacking"],
        description:
            "Shield your investments from the risk of exchange bankruptcies with our comprehensive protection services. We understand the potential concerns surrounding exchange insolvency and have developed robust measures to safeguard your funds.",
        pool: {
            cost: 0,
            costType: "Coming soon",
            risk: "medium",
            lightColor: "#805900",
            color: "#F8DD6C"
        },
        colors: {
            mainColor: "#F39B30",
            textColor: "#F39B30"
        }
    }
    // {
    //     img: DataImg,
    //     title: "Exchange & Custodian protection",
    //     props: ["Exchange assets theft", "Rug pull", "Bankruptcy", "Fraud", "Bad Management"],
    //     description:
    //         "Custom package that ensures exchange assets protection and custodian wallet protection against theft, rug pull, fraud, bad management, and bankruptcy. One of the most popular products provided by Martoms Brothers & Co.",
    //     cost: "1.999",
    //     blue: true
    // }
];

const CoverageBonds = () => {
    return (
        <section id="coverage-bonds" className="sections-spacing">
            <div className="container-main d-flex align-items-start justify-content-start w-100 flex-column">
                <UpperTitle
                    className="bg-main-black color-light-green px-2 rounded-1 mb-2"
                    title={"MOST USED"}
                />
                <Title title={"Products"} punctuation={"."} color={"#323232"} spacing={"big"} />
                {cardsData.map((el, i) => {
                    return (
                        <React.Fragment key={"card_coverage" + i}>
                            <CoverageCard {...el} />
                        </React.Fragment>
                    );
                })}
            </div>
        </section>
    );
};

export default CoverageBonds;
